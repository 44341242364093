import React from 'react'

function RightSideShema() {
  return (
    <div className='flex flex-col'>
        <div className="h-4 w-[80%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[50%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[30%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <br /><br />
        <div className="h-4 w-[80%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[50%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[30%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <br /><br />
        <div className="h-4 w-[80%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[50%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[100%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <div className="h-2 my-2 w-[30%] rounded-lg dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
        <br /><br />
    </div>
  )
}

export default RightSideShema