import React, { useEffect, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import useMainStore from '../../../Store/Store'
import { services } from '../../../Services/mainServices';
import axiosInstance from '../../../Utils/axios';
import ToastError from '../../../Utils/ToastErrors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../Common/Input';
import Toast from '../../../Utils/Toast';

function Settings() {
    const SettingsContent = () => {
        const params = useParams();
        const navigate = useNavigate();
        const {user} = useMainStore();
        const [details, setDetails] = useState<any>({
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            avatar: '',
            gender: user?.gender || '',
            university: user?.university || ''
        })
        const [image, setImage] = useState('');
        const [universities, setUniversities] = useState<any[]>([])
        useEffect(()=>{
            services.getUniversities()
            .then(res=>{
                setUniversities(res)
            }).catch(()=>{})
            if(user?.avatar){
                setImage(user?.avatar)
            }
            // eslint-disable-next-line
        },[])

        const handleUpdate = () => {
            const formData = new FormData();
            Object.keys(details).forEach(key=>{
                formData.append(key, details[key])
            })

            axiosInstance.patch(`/accounts/user/${user.id}/`, formData)
            .then(res=>{
                Toast('Changes saved successfully')
                navigate('/settings', {replace:true})
            }).catch(err=>ToastError(err))
        }



        return <div className='w-full h-full flex flex-col items-center sm:pt-[5rem]'>
            <section className="w-full sm:w-[500px] sm:p-10 dark:bg-darkBg sm:dark:text-white sm:bg-white flex flex-col items-center sm:border dark:border-gray-600 rounded-xl">
                {
                    params.action !== 'update-profile' ? <>
                        <b className="text-xl">User Profile</b>
                        <br />
                        <div className="w-32 h-32 mb-2 rounded-full overflow-hidden flex justify-center items-center border shadow-sm">
                            {
                                user?.avatar ? <img src={user?.avatar} alt="" className="w-full h-full object-fit" /> :
                                <i className="fa fa-user"></i>
                            }
                        </div>
                        <h1 className="text-xl font-semibold">{user?.first_name} {user?.last_name}</h1>
                        <small>{user?.email}</small>
                        <div className="flex items-center mt-2">
                            <i className="fa fa-star mr-2 mb-1"></i>
                            <b>{user?.rating?.toFixed(2)}</b>
                        </div>
                        <br />
                        <div className="grid grid-cols-3 gap-4">
                            <aside className="flex flex-col">
                                <small className="text-gray-500">Gender</small>
                                <p>{user?.gender || 'N/A'}</p>
                            </aside>
                            <aside className="flex flex-col">
                                <small className="text-gray-500">Active</small>
                                <p>{user?.active ? 'YES' : 'NO'}</p>
                            </aside>
                            <aside className="flex flex-col">
                                <small className="text-gray-500">Role</small>
                                <p>{user?.role || 'N/A'}</p>
                            </aside>
                        </div>
                        <br />
                        <button onClick={()=>navigate('/settings/update-profile')} className="flex items-center justify-center h-10 rounded-lg bg-emerald-600 text-white mx-auto w-[300px]">
                            <i className="fa fa-edit mr-3"></i>
                            <p>Update Profile</p>
                        </button>
                    </> : <>
                        <form onSubmit={e=>{
                            e.preventDefault();
                            handleUpdate();
                        }} action="" className='w-full flex flex-col items-center'>
                            <b className="text-xl">Update Profile</b>
                            <br />
                            <div className="w-32 h-32 mb-2 relative rounded-full overflow-hidden flex justify-center items-center border shadow-sm">
                                {
                                    image ? <img src={image} alt="" className="w-full h-full object-cover" /> :
                                    <i className="fa fa-camera"></i>
                                }
                                <input type="file" onChange={e=>{
                                    if(e.target.files){
                                        const file = e.target.files[0]
                                        setDetails({...details, avatar: file})
                                        const imageUrl = URL.createObjectURL(file)
                                        setImage(imageUrl)
                                    }
                                }} name="" id="" className='absolute cursor-pointer top-0 left-0 opacity-0 w-full h-full bg-red-500' />
                            </div>
                            <br />
                            <Input type='text' value={details.first_name} placeholder='Enter first name' onChange={(val)=>setDetails({...details, first_name: val})} className='h-12 mb-8'/>
                            <Input type='text' value={details.last_name} placeholder='Enter last name' onChange={(val)=>setDetails({...details, last_name: val})} className='h-12 mb-8'/>
                            <select value={details.university} onChange={e=>setDetails({...details, university:e.target.value})} className='h-12 mb-8 border border-gray-500 text-sm bg-transparent w-full rounded-lg px-3'>
                                <option value="">Change University</option>
                                {universities?.map((uni:any, idx:number)=><option key={`uni-${idx}`} value={uni?.id}>{uni?.name}</option>)}
                            </select>
                            <select value={details.gender} onChange={e=>setDetails({...details, gender:e.target.value})} className='h-12 mb-8 border border-gray-500 text-sm bg-transparent w-full rounded-lg px-3'>
                                <option value="">Change Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Male">Other</option>
                            </select>

                            <button className="w-full h-12 bg-theme text-white rounded-lg mb-4">Save Changes</button>
                            <div onClick={()=>navigate('/settings', {replace: true})} className="w-full h-12 flex items-center justify-center cursor-pointer dark:bg-gray-500 bg-gray-200 dark:text-white rounded-lg">Cancel</div>
                        </form>
                    </>
                }
                <br />
                <small className='text-center text-gray-500 mb-6'>If you want to reset your password for any security reasons, click <Link to={`/auth/forgot-password/`} className="text-sky-600">Here</Link> to reset your password.</small>

            </section>
        </div>
    }
  return <MainWrapper content={<SettingsContent/>}/>
}

export default Settings