import { useEffect, useState } from 'react'
import { peerExchangeServices } from '../../../../Services/peerExchanges'
import useMainStore from '../../../../Store/Store'

function usePeerExchange() {
    const user = useMainStore().user
    const [groupChats, setGroupChat] = useState<any>([])
    const [joinedChats, setJoinedChat] = useState<any>([])
    const [privateChats, setPrivateChats] = useState<any>({})
    const [create, setCreate] = useState(false);
    const [loading] = useState<any>('')

    const fetchData =async () => {
        const chats: any = await peerExchangeServices.getGroupChats('other');
        setGroupChat(chats);

        const joined: any = await peerExchangeServices.getGroupChats('joined')
        setJoinedChat(joined)

        const privateChats: any = await peerExchangeServices.getPrivateChats()
        setPrivateChats(privateChats)
    }

    const handleCreate =()=>{

    }

    const handleJoinGroup =async (chat:any) =>{
        const data = {
            group: chat?.id,
            user: user?.id
        }
        const res: any = await peerExchangeServices.joinGroup(data)
        if(res){
            fetchData();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    return {
        groupChats,
        joinedChats,
        fetchData,
        setCreate,
        create,
        handleCreate,
        handleJoinGroup,
        loading,
        privateChats,
        setPrivateChats
    }
}

export default usePeerExchange