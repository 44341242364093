import Toast from "./Toast";

export function copyText(text:string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    
    // Append the textarea to the document body
    document.body.appendChild(textarea);
    
    // Select the text within the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices
    
    // Execute the copy command
    document.execCommand('copy');
    
    // Remove the textarea from the document body
    document.body.removeChild(textarea);

    Toast('Copied')
}