import React, { ReactNode, useEffect, useState } from 'react'
import useMainStore from '../../../Store/Store';
import RightSideShema from './RightSideShema';

type props = {
    content: ReactNode,
    title: string,
    className?: string,
    loading?: boolean
}

function RightSideBar({content, title, className, loading}:props) {
  const {show, setShow} = useMainStore()
  const [innerWidth, setInnerWidth] = useState(0)

  useEffect(()=>{
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', ()=>{
      setInnerWidth(window.innerWidth)
      if(show){
        setShow(false)
      }
    })
    // eslint-disable-next-line
  },[])

  if(show && (innerWidth < 1280)){
    return <section className={`fixed right-0 top-0 xl:hidden h-full w-full flex justify-end`}>
      {loading ? <RightSideShema/> : <>
        <div onClick={()=>setShow(false)} className="absolute dark:bg-[#000000d3] bg-[#00000092] top-0 left-0 h-full w-full"></div>
        <div className={`${show ? `animatefromright ${innerWidth < 600 ? 'min-w-[50%] w-[70%':'min-w-[272px] w-[272px]'}` : 'transformRight'} min-w-[272px] w-[272px] shadow-md border-l dark:bg-[#222E44] bg-[#F1EFEF] rounded- pb-5 relative h-full overflow-y-auto ` }>
          {content}
        </div>
      </>}
    </section>
  }
  return (
    <div onClick={e=>e.stopPropagation()} className={`xl:min-w-[272px] xl:w-[272px] dark:text-gray-200  xl:h-full h-fit ml-[2rem] flex flex-col overflow-y-auto absolute bottom-0 right-0 xl:relative ${show ? 'right-1 xl:right-0':'right-0'} ${className}`}>
      {loading ? <RightSideShema/> : <>
        <div onClick={()=>setShow(false)} className="w-full h-fit hidden xl:block dark:bg-darkBg bg-[#F1EFEF] rounded-xl pb-5">
          {content}
        </div>
      </>}
    </div>
  )
}

export default RightSideBar