import React, { useEffect, useState } from 'react'
import { FaHistory, FaUsers } from "react-icons/fa";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { SiPeerlist } from "react-icons/si";
import { useLocation, useNavigate } from 'react-router-dom';
import { peerEx, xray } from '../../../Assets/Images';
import MessageInput from '../../../Common/MessageInput';
import LineLoading from '../../../Common/LineLoading';
import { upgradeServices } from '../../../Services/upgradeServices';
import Toast from '../../../Utils/Toast';

type props = {
    data: {scan:any[],patients:any[],groups: any[],peers:any[]}
}


function Redesigned({data}:props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [mes, setMes] = useState('');
    const [verifying, setVerifying] = useState(false)

    useEffect(()=>{
        const payment_session_id = localStorage.getItem('payment_session_id') || ''
        if(location.pathname === '/payments/success' && payment_session_id){
            // verifying payment
            setVerifying(true)
            upgradeServices.verification(payment_session_id)
            .then(res=>{
                setVerifying(false)
                localStorage.removeItem('payment_session_id')
                navigate('/',{replace:true});
                Toast('Payment Successfull')    
            }).catch(err=>{
                setVerifying(false)
                Toast(err?.response?.data?.message||'Sorry, unsuccessful')
            })
        }
        localStorage.removeItem('prev')
        // eslint-disable-next-line
    },[location.pathname])
  return (
    <div className='w-full h-full'>
        {
            verifying && <div className="pb-3 animatefromleft">
                <LineLoading/>
            </div>
        }
        
        <section className='grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-4'>
            <aside onClick={()=>navigate(`/smart-scan`)} className="w-full hover:bg-gray-50 transition-all duration-300 hover:scale-105 cursor-pointer rounded-xl p-4 dark:text-gray-200 dark:bg-darkBg bg-white shadow animateshow1">
                <div className="flex items-center">
                    <FaHistory className='text-lg mr-2'/>
                    <b>Scan History</b>
                </div>
                    <small className='text-gray-500'>Patient scan histories</small>
                    <h1 className='text-[2rem] text-indigo-600 font-bold'>{data.scan?.length || 0}</h1>
            </aside>
            <aside onClick={()=>navigate(`/peer-exchange`)} className="w-full hover:bg-gray-50 transition-all duration-300 hover:scale-105 cursor-pointer rounded-xl p-4 dark:text-gray-200 dark:bg-darkBg bg-white shadow animateshow2">
                <div className="flex items-center">
                    <FaUsers className='text-lg mr-2'/>
                    <b>Joined Groups</b>
                </div>
                <small className='text-gray-500'>Your current joined groups</small>
                <h1 className='text-[2rem] text-sky-600 font-bold'>{data.groups?.length || 0}</h1>
            </aside>
            <aside onClick={()=>navigate(`/smart-scan`)} className="w-full hover:bg-gray-50 transition-all duration-300 hover:scale-105 cursor-pointer rounded-xl p-4 dark:text-gray-200 dark:bg-darkBg bg-white shadow animateshow3">
                <div className="flex items-center">
                    <MdOutlineHealthAndSafety className='text-lg mr-2'/>
                    <b>Patients</b>
                </div>
                <small className='text-gray-500'>Your recorded patients</small>
                <h1 className='text-[2rem] text-emerald-600 font-bold'>{data.patients?.length || 0}</h1>
            </aside>
            <aside onClick={()=>navigate(`/peer-exchange`)} className="w-full hover:bg-gray-50 transition-all duration-300 hover:scale-105 cursor-pointer rounded-xl p-4 dark:text-gray-200 dark:bg-darkBg bg-white shadow animateshow4">
                <div className="flex items-center">
                    <SiPeerlist className='text-lg mr-2'/>
                    <b>Peers</b>
                </div>
                <small className='text-gray-500'>Your suggested peers</small>
                <h1 className='text-[2rem] text-blue-600 font-bold'>{data.peers?.length || 0}</h1>
            </aside>
        </section>

        <section className="flex flex-col md:grid md:grid-cols-2 2xl:grid-cols-2 gap-4 mb-4">
            <section className="rounded-2xl overflow-hidden md:h-auto h-[350px] md:max-h-[300px] relative">
                <img src={xray} alt="" className="object-cover h-full w-full" />
                <div className="absolute flex justify-center  items-center flex-col top-0 w-full h-full cursor-pointer left-0 bg-gradient-to-tr hover:from-[#4297c836] from-[#4297c88f] hover:to-[#c4fff136] to-[#c4fff1a5]">
                    <b className="sm:text-xl text-deepTheme">Smart Scan</b>
                    <br />
                    <div onClick={()=>navigate('/smart-scan')} className="rounded-2xl p-4 2xl:w-[300px] 2xl:min-h-[200px] border border-dashed border-gray-100 flex flex-col justify-center items-center">
                        <svg className='w-[35px] md:w-[50px]' viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.8274 18.1057C14.8859 18.1057 15.7449 18.9647 15.7449 20.0233C15.7449 21.0818 14.8859 21.9408 13.8274 21.9408H11.4419C7.30244 21.9408 3.93771 25.3056 3.93771 29.4424V41.9068C3.93771 46.0462 7.30244 49.4109 11.4419 49.4109H39.8989C44.0357 49.4109 47.403 46.0462 47.403 41.9068V29.4194C47.403 25.2953 44.0485 21.9408 39.927 21.9408H37.5159C36.4574 21.9408 35.5984 21.0818 35.5984 20.0233C35.5984 18.9647 36.4574 18.1057 37.5159 18.1057H39.927C46.163 18.1057 51.2382 23.1809 51.2382 29.4194V41.9068C51.2382 48.1607 46.1502 53.2461 39.8989 53.2461H11.4419C5.19054 53.2461 0.102539 48.1607 0.102539 41.9068V29.4424C0.102539 23.1911 5.19054 18.1057 11.4419 18.1057H13.8274ZM27.027 1.30147L34.4826 8.78773C35.2292 9.53942 35.2266 10.7513 34.4775 11.4979C33.7258 12.2445 32.5139 12.2445 31.7673 11.4928L27.5854 7.29648L27.5869 35.356H23.7518L23.7502 7.29648L19.574 11.4928C19.2007 11.8712 18.7072 12.0579 18.2163 12.0579C17.728 12.0579 17.2371 11.8712 16.8638 11.4979C16.1147 10.7513 16.1095 9.53942 16.8587 8.78773L24.3117 1.30147C25.0302 0.577897 26.3085 0.577897 27.027 1.30147Z" fill="#F7F4F1"/>
                        </svg>
                        <small className='text-center w-[200px] mt-4'>Click to start uploading patient scans</small>
                    </div>
                </div>
            </section>
            <section onClick={()=>navigate(`/peer-exchange`)} className="rounded-2xl hover:opacity-50 cursor-pointer md:h-auto h-[230px] md:max-h-[300px] flex justify-center items-center flex-col dark:bg-darkBg bg-gradient-to-tr dark:from-transparent dark:to-transparent from-[#4297c8a1] to-[#c4fff194]">
                <img src={peerEx} alt="" />
                <br />
                <b className="text-skyBlue sm:text-xl">Peer Exchange</b>
            </section>
        </section>
        <div onClick={()=>navigate(`/learn-hub`)} className="w-full h-[300px] flex flex-col items-center rounded-2xl p-6 dark:text-gray-200 dark:bg-darkBg bg-[#E0E6EB] mb-4">
            <section className="w-full h-full flex flex-col items-center justify-center">
                <b className="sm:text-lg dark:text-white text-deepTheme text-center">What do you wish to learn today?</b>
                <br />
                <small className='text-center'>Enter your chat in the text input below to start a new chat with our smart bot.</small>
                <br />
                <div className="rounded-3xl hidden bg-white p-1 w-[200px] overflow-hidden">
                    <input placeholder='Add Topic here' type="text" className="w-full h-full text-xs sm:text-sm border-none outline-none px-2" />
                    <aside className="min-w-[40px] h-[40px] rounded-full flex justify-center items-center bg-skyBlue">
                    <small className="fa fa-plus text-white"></small>
                    </aside>
                </div>
                <br />
            </section>
            <MessageInput hideAttach mes={mes} setMes={(val)=>setMes(val)} limitheight={100}/>
        </div>

        {/* <section className="w-full p-4 rounded-lg bg-white mb-4 animateshow2">
            <div className="w-full flex items-center justify-between">
                <b className='mb-2'>Recent Scan History</b>
                <small className="text-sky-600 hover:text-sky-900 hover:font-semibold cursor-pointer">Sell all</small>

            </div>
            <Empty button={<button onClick={()=>navigate(`/smart-scan`)} className='w-[200px] h-10 mt-2 text-sm rounded-2xl text-gray-700 font-bold bg-gray-100 hover:text-gray-100 hover:bg-theme transition-all duration-500 '>Start new scan</button>}/>
        </section>
        <section className="w-full p-4 rounded-lg bg-white animateshow3">
            <div className="w-full flex items-center justify-between">
                <b className='mb-2'>Recent Chat History</b>
                <small className="text-sky-600 hover:text-sky-900 hover:font-semibold cursor-pointer">Sell all</small>

            </div>
            <Empty button={<button onClick={()=>navigate(`/learn-hub`)} className='w-[200px] h-10 mt-2 text-sm rounded-2xl text-gray-700 font-bold bg-gray-100 hover:text-gray-100 hover:bg-theme transition-all duration-500 '>Start new chat</button>}/>
        </section> */}
        {/* <div className="flex h-full">

        </div> */}
    </div>
  )
}

export default Redesigned