const set = async (name:string, value:any) => {
    try {
        const type = typeof value;

        switch (type) {
            case 'object':
                localStorage.setItem(name, JSON.stringify(value));
                break;
            default:
                localStorage.setItem(name, value);
        }
    } catch (error:any) {
        console.error(`Error saving to session storage: ${error.message}`);
    }
};

const get:any = async (name:string) => {
    try {
        const storedValue = localStorage.getItem(name);

        if (storedValue === null) {
            return null;
        }
        let parsedValue;

        try {
            parsedValue = JSON.parse(storedValue);
        } catch (error) {
            parsedValue = storedValue;
        }

        return parsedValue;
    } catch (error: any) {
        console.error(`Error retrieving from session storage: ${error.message}`);
        return null;
    }
};


const local = {
    get, set
}

export default local