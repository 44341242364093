import axios from 'axios';
import { baseUrl } from '../Services/mainServices';

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  // Determine the Content-Type based on the data type
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  // Attach the Authorization token if available
  const accessToken = JSON.parse(localStorage.getItem('tokens') || '{}')?.access_token;
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response?.status === 401 && !error.config._retry) {
    localStorage.clear();
    localStorage.setItem('prev', window.location.pathname);
    if(!window.location.pathname.includes('/auth')){
      alert('Session has expired, please login to continue');
    }

    window.location.href = '/auth/login'; // Redirect to login page
  }

  return Promise.reject(error);
});

export default axiosInstance;
