import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { loading } from '../../../Assets/Icons';
import { learnService } from '../../../Services/learnHubServices';
import { copyText } from '../../../Utils/copyText';
import ToastError from '../../../Utils/ToastErrors';

type props = {
    close: ()=>void,
    selectedChat: any,
    setSelectedChat: (val:any)=>void
}

function ShareChat({close, selectedChat, setSelectedChat}:props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const [link, setLink] = useState('');
    const [copied, setCopied] = useState(false)

    const activate =async () => {
        setLoading(true)
        let data = {
            is_shared: true
        }
        console.log(location.state?.activeChat?.id)
        learnService.activateChatSharing(location.state?.activeChat?.id, data)
        .then(res=>{
            setLink(`${window.location.protocol}//${window.location.host}/botchat/${selectedChat?.id}`)
            navigate(location.pathname, {state: {activeChat: {...selectedChat, is_shared: true}}, replace: true})
        }).catch(err=>{
            ToastError(err)
        })
    }

    useEffect(()=>{
        if(selectedChat?.is_shared){
            setLink(`${window.location.protocol}//${window.location.host}/botchat/${selectedChat?.id}`)
        }
    },[selectedChat])

    return <div className='w-full h-auto flex flex-col justify-center'>
        {
            selectedChat?.is_shared ? <>
                <p className='text-[14px]'>Share this active chat by copying the link below.</p>
                <p className='text-[14px]'>Conversation here will be seen by anyone with this link but they can't join.</p>
            </> : <>
                <p className='text-[14px]'>This active chat is not sharable.</p>
                <p className='text-[14px]'>Activate sharing to get a link for sharing this chat.</p>
            </>
        }

        <br /><br />
        {
            link && <p className="text-sky-600 hover:font-semibold cursor-pointer mb-3">
                <i className="fa fa-edit mr-1"></i>
                <a href={link} target='_blank' rel="noreferrer">Open Link</a>
            </p>
        }
        
        {
            !selectedChat?.is_shared ? <button onClick={()=>activate()} className={`w-full h-16 mb-3 rounded-3xl ${isLoading ? 'bg-gray-100 border border-theme':'bg-theme'} text-white flex justify-center items-center`}>
                {
                    isLoading ? <img src={loading} alt='sdfwe' className='w-5'></img>: 'Activate Sharing'
                }
                
            </button> : <div className="w-full h-16 flex border rounded-3xl p-1 overflow-hidden mb-3">
            <div className="pl-3 w-full h-full flex items-center text-gray-600 text-sm overflow-x-auto">{link?.slice(0,30)}...</div>
            <button onClick={()=>{
                setCopied(true)
                copyText(link)
            }} className={`min-w-32 h-full flex items-center justify-center rounded-3xl ${copied?'bg-emerald-600':'bg-theme'} text-white`}>
                {copied ? 'Copied':'Copy Link'}
                {copied && <small className='fa fa-check text-white ml-2'></small>}
            </button>
        </div>
        }
        
        <i className='text-gray-400 text-xs'>Read terms and conditions under sharing chats</i>
    </div>
}

export default ShareChat