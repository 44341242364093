import React from 'react'
import { navActive } from '../../../Assets/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import useMainStore from '../../../Store/Store'

type props = {
    icon: string,
    iconActive: string,
    name: string,
    link: string
}

function NavItem({icon, iconActive, name, link}:props) {
    const theme = useMainStore().theme;
    const location = useLocation();
    const navigate = useNavigate();
    
    return <div onClick={()=>navigate(link)} className={`flex cursor-pointer group items-center w-full pl-5 rounded-l-[1.5rem] ${location.pathname.includes(link) && 'dark:bg-darkTheme bg-[#F7F4F1]'} h-[45px] relative mb-2`}>
            {
                location.pathname.includes(link) && (
                    theme === 'light' ? <img src={navActive} alt="" className='h-[240%] m-auto mb-[0.05rem] object-cover absolute -right-10 lg:flex hidden' /> :
                    <svg className='h-[240%] m-auto mb-[0.05rem] object-cover absolute -right-10 lg:flex hidden' viewBox="0 0 77 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M76.1775 58.75C76.1775 80.2891 58.5393 111.25 37.0002 111.25C37.0002 100.75 31.5002 90.25 20.5002 83.75C14.0002 80.25 9.50017 79.75 3.00017 79.75C-11.8457 75.9529 55.1991 73.8335 57.0001 58.75C60.8078 46.0579 -2.0439 34.4068 5.50012 31.75C11.5001 31.75 18.5001 29.75 25.0001 24.75C30.0001 20.524 36.5001 13.25 37.0001 0.25C58.5392 0.25 76.1775 37.2109 76.1775 58.75Z" fill="#1A1D2D"/>
                    </svg>
                )
                
            }

            <img src={location.pathname.includes(link) ? iconActive : icon} alt="" className='w-[20px] mr-3 group-hover:scale-105' />
            <p className={`group-hover:text-skyBlue ${location.pathname.includes(link)?'dark:text-[#F7F4F1] text-[#161048]':'text-[#F7F4F1]'}`}>{name}</p>
        </div>
}

export default NavItem