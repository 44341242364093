import React, { useEffect, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import RightSideBar from '../Components/RightSideBar'
import { Link, useNavigate } from 'react-router-dom'
import Redesigned from './Redesigned'
import useMainStore from '../../../Store/Store'
import { scanServices } from '../../../Services/ScanServices'
import { peerExchangeServices } from '../../../Services/peerExchanges'
import Empty from '../../../Common/Empty'
import { upgradeServices } from '../../../Services/upgradeServices'
import { convertDate, convertTime } from '../../../Utils/convertTime'
import axiosInstance from '../../../Utils/axios'
import DashShema from './DashShema'

function Dashboard() {
  const [loading, setLoading] = useState(true)
  const DashContent = () => {
    const navigate = useNavigate();
    const store = useMainStore()
    const {setShow} = store;
    const [data, setData] = useState({
      scan: [],
      groups: [],
      patients: [],
      peers: []
    })

    const fetchData =async () => {
      const his:any = await scanServices.getScans(store.user?.id);
      if(his){
        setData(prev=>{
          return {...prev, scan:his.results}
        })
      }

      const joined: any = await peerExchangeServices.getGroupChats('joined')
      if(joined){
        setData(prev=>{
          return {...prev, groups:his.results}
        })
      }

      const privateChats: any = await peerExchangeServices.getPrivateChats()
      if(privateChats){
        setData(prev=>{
          return {...prev, peers:his.results}
        })
      }

      axiosInstance.get(`/smartscans/users/patients`)
        .then(res=>{
          setData(prev=>{
            return {...prev, patients: res.data?.results}
          })
        }).catch(err=>{
            console.log(err)
        })

      upgradeServices.userPlan()
      .then(res=>{
          if(res.data && !res.data?.is_expired){
            store.setTransaction({
              ...res.data?.transaction,
              can_join_groups_no: res.data?.can_join_groups_no,
              id: res.data?.id,
              is_expired: res.data?.is_expired,
              learninghub_credits: res.data?.learninghub_credits,
              reloaded_at: res.data?.reloaded_at,
              smartscan_credits: res.data?.smartscan_credits,
            })

            store.setUser(res.data?.user)
          }else{
            store.setTransaction(null)
          }
      }).catch(()=>{}).finally(()=>setLoading(false))
    }

    useEffect(()=>{
      fetchData()
      // eslint-disable-next-line
    },[])

    return <div className='w-full flex h-full relative'>
      <section className="w-full flex flex-col overflow-hidden lg:px-0 sm:px-[8%] overflow-y-auto">
        { loading ? <DashShema/> : <Redesigned data={data}/> }
      </section>
      <RightSideBar loading={loading} className='animateshow3' title='Histories' content={<>
        <div className="w-full flex items-center justify-between px-6 pt-3">
        </div>

        <div className="p-1 px-6 flex focus-within:flex-col focus-within:items-start items-center justify-between transition-all duration-500 dark:text-white">
          <b>Scan history</b>
        </div>
        {
          (data.scan?.slice(0,2))?.map((chat:any, idx)=>{
            return <div onClick={()=>{
              navigate(`/smart-scan/${chat?.id}`)
              setShow(false)
            }} key={`chat-${idx}`} className="w-full hover:bg-[#4297c81f] dark:text-white cursor-pointer p-5 px-6 flex flex-col">
              <h1 className="text-[14px] mb-2 font-semibold"><b className='mr-2'>{idx+1}</b>{chat?.description ? chat?.description?.slice(0,17) : 'N/A'}...</h1>
              <p className='text-[12px] mb-2 dark:text-gray-400'>{chat?.description?.slice(0,50)}... </p>
              <div className="w-full flex items-center justify-between">
                <small>{convertDate(chat?.created_at)}</small>
                <small>{convertTime(chat?.created_at)}</small>
              </div>
            </div>
          })
        }
        {data.scan?.length>2 && <Link to={`/smart-scan`} className='text-sky-600 cursor-pointer hover:font-extrabold px-6 font-bold text-sm'>See All</Link>}
        {data.scan?.length === 0 && <Empty/>}

        <br />
        <br />
        <br />
        <div className="p-1 px-6 flex focus-within:flex-col focus-within:items-start items-center justify-between transition-all duration-500 dark:text-white">
          <b>Chat history</b>
          {/* <section className="focus-within:w-full h-10 group focus-within:mt-2 rounded-xl overflow-hidden flex items-center justify-center bg-[#6B7280] text-white">
            <input placeholder='Search here ...' type="text" className="w-full h-full pl-3 text-gray-50 outline-none bg-transparent border-none text-xs hidden group-focus-within:flex" />
            <button className="w-8 min-w-8 h-8 rounded-xl flex items-center justify-center bg-[#6B7280] text-white">
              <i className="fa fa-search text-xs"></i>
            </button>
          </section> */}
        </div>
        {
          (data.groups?.slice(0,2))?.map((group:any, idx)=>{
            return <div onClick={()=>{
              navigate(`/peer-exchange/${group?.id}`)
              setShow(false)
            }} key={`peer-${idx}`} className="w-full hover:bg-[#4297c81f] dark:text-white cursor-pointer p-5 px-6 flex flex-col">
              <h1 className="text-[14px] mb-2 font-semibold"><b className='mr-2'>{idx+1}</b>{group?.description ? group?.description?.slice(0,17) : 'N/A'}...</h1>
              <p className='text-[12px] mb-2 dark:text-gray-400'>{group?.description?.slice(0,50)}... </p>
              <div className="w-full flex items-center justify-between">
                <small>{convertDate(group?.created_at)}</small>
                <small>{convertTime(group?.created_at)}</small>
              </div>
            </div>
          })
        }
        {data.groups?.length>2 && <Link to={`/peer-exchange`} className='text-sky-600 cursor-pointer hover:font-extrabold px-6 font-bold text-sm'>See All</Link>}
        {data.groups?.length === 0 && <Empty/>}
        </>}/>
    </div>
  }
  return <MainWrapper loading={loading} content={<DashContent/>}/>
}

export default Dashboard