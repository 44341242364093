import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Dashboard from './Pages/Main/Dashboard/Dashboard'
import Login from './Pages/Auth/Login'
import Signup from './Pages/Auth/Signup'
import ForgotPassword from './Pages/Auth/ForgotPassword'
import Protected from './Common/ProtectedRoute'
import Learn from './Pages/Main/Learn/Learn'
import PeerExchange from './Pages/Main/PeerExchange/PeerExchange'
import EmailVerification from './Pages/Auth/EmailVerification'
import SharedChat from './Pages/Main/Learn/SharedChat'
import SmartScan from './Pages/Main/SmartScan/SmartScan'
import Settings from './Pages/Main/Settings/Settings'


function Routers() {

  return (
    <Routes>
        <Route path='/' element={<Protected page={<Dashboard/>}/>}/>
        <Route path='/learn-hub/:chat?' element={<Protected page={<Learn/>}/>}/>
        <Route path='/peer-exchange/:chatId?' element={<Protected page={<PeerExchange/>}/>}/>
        <Route path='/peer-exchange/group/:chatId?' element={<Protected page={<PeerExchange/>}/>}/>
        <Route path='/peer-exchange/peer/:peer_id?' element={<Protected page={<PeerExchange/>}/>}/>
        <Route path='/botchat/:chatId?' element={<SharedChat/>}/>
        <Route path='/smart-scan/' element={<Protected page={<SmartScan/>}/>}/>
        <Route path='/smart-scan/:scanId?' element={<Protected page={<SmartScan/>}/>}/>
        <Route path='/settings/:action?' element={<Protected page={<Settings/>}/>}/>
      

        {/* Auth Routes .......................... */}
        <Route path='/auth/login' element={<Login/>}/>
        <Route path='/auth/signup/:type?' element={<Signup/>}/>
        <Route path='/auth/email-verification/:email' element={<EmailVerification/>}/>
        <Route path='/auth/forgot-password/:step?' element={<ForgotPassword/>}/>


        <Route path='/*' element={<Protected page={<Dashboard/>}/>}/>
        
    </Routes>
  )
}

export default Routers