import React, { useEffect, useState } from 'react'
import AuthWrapper from './Components/AuthWrapper'
import { authImage2 } from '../../Assets/Images'
import { useParams } from 'react-router-dom'
import OTPInput from '../../Common/OtpInput'
import useAuth from './Hooks/authHooks'

function EmailVerification() {

    const VerificationContent = () => {
        const [code, setCode] = useState('')
        const params = useParams();
        const [count, setCount] = useState<number>(60);
        useEffect(() => {
            if (count === 0) return;
            const timer = setInterval(() => {
                setCount(prevCount => prevCount - 1);
            }, 1000);

            return () => clearInterval(timer);
        }, [count]);

        const {handleVerify} = useAuth()

        return <div className={`w-full xs:w-[450px] sm:w-[500px] sm:p-6`}>
            <section className="w-full h-fit flex flex-col md:mb-[12rem]">

                <section className="w-full h-fit flex flex-col md:mb-[5rem]">
                        <div onClick={()=>window.history.back()} className="mb-4 w-fit flex items-center cursor-pointer hover:text-theme hover:font-bold">
                            <i className="fa fa-arrow-left mr-2"></i>
                            <small>Go Back</small>
                        </div>
                        <h1 className="font-[600] text-xl sm:text-2xl mb-4 sm:mb-6">Verify your email</h1>
                        <small>We have sent you a code on <b className="text-sky-600">{params.email}</b></small>

                        <br />
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        handleVerify(e, {email: params.email, code})
                    }} action="" className="w-full flex flex-col">
                        {/* <Input value={code} onChange={(code:any)=>setCode(code)} type='text' placeholder='Verification code' className='mb-6 h-[48px]' /> */}

                        <OTPInput setValue={(text:string)=>setCode(text)}/>
                            <br />
                        <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between mb-6">
                            <button className="rounded-xl bg-theme text-white w-full sm:mt-0 mt-4 h-[48px]">Verify Code</button>
                        </section>
                        <div className="my-2">Didn't get code? <b>Get new code in 1 minute</b></div>
                        {
                            count === 0 ? <button className="w-fit h-10 px-10 rounded-lg border border-theme text-theme hover:text-white hover:bg-theme">
                                Request Code
                            </button> : <div className="flex items-center text-sky-600">
                                <p className=' mr-1.5'>Request Code</p>
                                <b className="">{count}</b>
                            </div>
                        }
                        
                        
                    </form> 
                </section>
            </section>

            <section className="text-[12px] sm:text-sm text-gray-500 md:mt-0 mt-[4rem]">
                <p>Protected by reCAPTCHA and subject to the <b className="text-theme font-normal">eighty-nine Privacy Policy</b> and <b className="text-theme font-normal">Terms of Service.</b></p>
            </section>
        </div>
    }

  return <AuthWrapper sideImage={authImage2} type='' content={<VerificationContent/>}/>
}

export default EmailVerification