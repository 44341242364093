import React, {  } from 'react';
import Routers from './router';
import { ToastContainer } from 'react-toastify';



function App() {
  // const store = useMainStore();
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(()=>{
  //   if(!store.user && !location.pathname.includes('auth')){
  //     navigate('/auth/login')
  //   }
  // })

  return (
    <div>
      <Routers/>
      <ToastContainer/>
    </div>
  );
}

export default App;
