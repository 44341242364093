import React, { useEffect, useState } from 'react'
import MessageInput from '../../../Common/MessageInput'
import { scanServices } from '../../../Services/ScanServices'
import { useParams } from 'react-router-dom'
import { mediaUrl } from '../../../Services/mainServices'

function ScanDetail() {
    const params = useParams();
    const [detail, setDetail] = useState<any>({
        message: '',
        scan: {}
    });
    const [viewDes, setViewDes] = useState(false)

    const fetchData =async ()=>{
        const res:any = await scanServices.getScanDetails(params.scanId);
        console.log(res)
        setDetail((prev:any)=>{
            return {...prev, scan: res?.data?.data}
        })
    }

    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[])

    return <div className="w-full flex flex-col h-full rounded-2xl overflow-auto">
        <div className="h-fit sm:h-full w-full flex 2xl:flex-row flex-col mb-6 sm:bg-gradient-to-tr dark:from-[#4297c8b2] dark:to-[#c4fff158] from-[#4297C8] to-[#C4FFF1] md:p-10 p-0 rounded-2xl sm:overflow-y-auto">
            <section className="sm:min-h-[300px] w-[75%] sm:w-auto md:max-w-[250px] 2xl:max-w-auto md:min-w-[250px] lg:min-w-[400px] overflow-hidden rounded-xl md:mr-[1rem] lg:mr-[3rem] 2xl:mb-0 md:mb-6 md:p-0 sm:p-6 relative">
                <img src={`${mediaUrl}${detail.scan?.scan?.image}`} alt="" className="w-full h-full object-cover rounded-xl" />
            </section>
            <section className="w-full flex flex-col items-start md:p-0 sm:p-6 pt-6">
                <div className="flex items-center justify-between mb-4 dark:text-white">
                    <b>{detail.scan?.diagnosis}</b>
                    <b className='ml-1'>{(detail.scan?.accuracy/10)*100}%</b>
                </div>
                <div className="w-full h-2 sm:h-3 flex bg-gray-200 overflow-hidden rounded-lg">
                    <aside style={{width: `${(detail.scan?.accuracy/10)*100}%`}} className={`h-full ${
                        (detail.scan?.accuracy/10)*100 > 70 ? 'bg-green-600' : 
                        (detail.scan?.accuracy/10)*100 > 50 ? 'bg-orange-600' : 'bg-red-600'
                    }`}></aside>
                </div>
                <br />
                <b className='dark:text-white text-theme dark:mb-2'>Description</b>
                <small className='sm:text-[0.8rem] text-[0.75rem] dark:text-white '>
                    {
                        viewDes ? detail.scan?.description : detail.scan?.description?.slice(0,800)
                    }
                </small>
                {
                    (detail.scan?.description?.length > 800) && <b onClick={()=>setViewDes(!viewDes)} className='sm:text-white sm:dark:text-theme dark:text-white text-theme cursor-pointer sm:hover:text-theme hover:text-sky-600'><small>View {viewDes ? 'Less':'More'}</small></b>
                }
            </section>
        </div>
        <section className="flex flex-col h-fit w-full">
            <b className='my-2 ml-1 sm:text-[1rem] text-sm dark:text-gray-100'>Chat with eighty-nine to find out more</b>
            <MessageInput mes={detail.message} setMes={val=>setDetail({...detail, message: val})} limitheight={150} />
        </section>

    </div>

}

export default ScanDetail