import { FormEvent, useEffect, useState } from 'react'
import { services } from '../../../Services/mainServices'
import { authServices } from '../../../Services/authServices'
import { useNavigate } from 'react-router-dom'
import useMainStore from '../../../Store/Store'
import local from '../../../Utils/Local'
import Toast from '../../../Utils/Toast'
import ToastError from '../../../Utils/ToastErrors'

function useAuth() {
    const [universities, setUniversities] = useState<any[]>([])
    const navigate = useNavigate();
    const store = useMainStore();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        services.getUniversities()
        .then(data=>{
            console.log(data)
            setUniversities(data)
        }).catch(err=>{setUniversities([])})
    },[])

    const handleSubmit =async (e:FormEvent, data:any, type: 'student'|'partnership', hospital?:string) =>{
        setLoading(true)
        e.preventDefault();
        let reqBody = data;
        Object.keys(reqBody).forEach(key=>{
            if(!reqBody[key]) delete reqBody[key]
        })
        let res:any = await authServices.signup(reqBody, type, hospital||'')
        if(res){
            setLoading(false);
            navigate(`/auth/email-verification/${data.email}`);
        }
    }

    const handleLogin = async(e:FormEvent, data:any,) =>{
        e.preventDefault()
        setLoading(true)
        let {email} = data;
        let res:any = await authServices.signin(data)
        let status = res.status ?? res?.response?.status ?? 'failed';
        if(res){
            setLoading(false)
            console.log(res, data)
            switch(status){
                case 200:
                    local.set('user', res.data?.user)
                    store.setUser(res.data?.user)
                    local.set('tokens', res.data?.tokens)
                    store.setTokens(res.data?.tokens)
    
                    Toast('Login successful')
                    if(store.user){
                        navigate(localStorage.getItem('prev')||'/', {replace: true})
                    }else{
                        window.location.href = localStorage.getItem('prev')||'/'
                    }
                    localStorage.removeItem('prev')
                    break;
                case 401:
                    Toast(res.response.data, 'warning')
                    window.location.href = (`/auth/email-verification/${email}`)
                    break;
                default:
                    ToastError(res)
                    return
            }
        }
    }

    const handleVerify = (e:FormEvent, data:any) =>{
        e.preventDefault();
        authServices.verifyEmail(data)
        .then((data:any)=>{
            local.set('user', data.user);
            local.set('tokens', data.tokens)
            store.setUser(data?.user)
            store.setTokens(data?.tokens)
            if(store.user){
                navigate(localStorage.getItem('prev')||'/', {replace: true})
            }else{
                window.location.href = localStorage.getItem('prev')||'/'
            }
        }).catch(err=>{ToastError(err)})
    }

    return {
        universities,
        handleSubmit,
        handleVerify,
        handleLogin,
        loading
    }
}

export default useAuth