import { baseUrl } from "./mainServices";
import axiosInstance from "../Utils/axios";
import ToastError from "../Utils/ToastErrors";

export const peerExchangeServices = {
    getGroupChats: async (type: string)=>{
        try{
            
            const res = await axiosInstance.get(`${baseUrl}/peerexchange/group?type=${type}`, );
            return res.data;
        }catch(err){
            return err
        }
    },
    getPrivateChats: async ()=>{
        try{
            const res = await axiosInstance.get(`${baseUrl}/accounts/users/listing/?unread_chats=true`, );
            return res.data;
        }catch(err){
            return err
        }
    },
    getGroupMessage: async (chatId: string)=>{
        try{
            
            const res = await axiosInstance.get(`${baseUrl}/peerexchange/group/${chatId}/messages/list`, );
            return res.data;
        }catch(err){
            return err
        }
    },
    createGroupMessage: async (data:any)=>{
        try{
            
            const res = await axiosInstance.post(`${baseUrl}/peerexchange/group/messages/message/`, data, );
            return res;
        }catch(err){
            ToastError(err)
            return err
        }
    },
    createGroup: async (data:any)=>{
        try{
            
            const res = await axiosInstance.post(`${baseUrl}/peerexchange/group/`, data, );
            return res.data;
        }catch(err){
            ToastError(err)
            return err
        }
    },
    joinGroup: async (data:any)=>{
        try{
            
            const res = await axiosInstance.post(`${baseUrl}/peerexchange/group/memberships/membership/member/`, data, );
            return res.data;
        }catch(err){
            ToastError(err)
            return err
        }
    },
    deletePost: async (id:any)=>{
        try{
            
            const res = await axiosInstance.delete(`${baseUrl}/peerexchange/group/messages/message/${id}/`, );
            return res.data;
        }catch(err){
            return err
        }
    },
    getAllPrivateChat: async (id:any)=>{
        try{
            const res = await axiosInstance.get(`${baseUrl}/peerexchange/group/chats/privatechat/privatemessage/${id}/`, );
            return res.data;
        }catch(err){
            return err
        }
    },
}