import React, { ReactNode } from 'react'
import { FaUserDoctor } from "react-icons/fa6";


type props = {
    comment?: string | ReactNode,
    button?: ReactNode,
    size?: number
}

function Empty({comment, button, size}:props) {
  return (
    <div className='flex flex-col w-full items-center justify-center py-[5rem]'>
        <FaUserDoctor className={`text-[40px] text-gray-300 mb-2`}/>
        {/* <img src={'https://media.healthecareers.com/wp-content/uploads/2022/02/11204020/placeholderdoctor.jpg.webp'} alt="" width={size ?? 100} className='rounded-xl opacity-30' /> */}
        <small className={`text-gray-400 text-center ${button && 'mb-3'}`}>{comment || 'Sorry, nothing here'}</small>

        {button}
    </div>
  )
}

export default Empty