import React, { ReactNode } from 'react'
import { Logo1, Logo2 } from '../../../Assets/Images'

type props = {
  subHead?: ReactNode,
  content: ReactNode,
  sideImage: string,
  type: string,
}

function AuthWrapper({content, subHead, sideImage, type}:props) {
  return (
    <div className={`w-screen h-screen flex`}>
      {/* Side Image ... */}
      <section className="min-w-[450px] xl:min-w-[510px] lg:flex hidden h-full relative bg-[#121734]">
        <img src={sideImage} alt="" className="object-cover w-full h-full" />
        <div className={`absolute top-0 left-0 w-full h-full p-6 flex flex-col justify-between text-white bg-[rgba(14,54,146,0.7)]`}>
          <aside className="w-full flex min-h-[100px items-center">
            <img src={Logo2} alt="" />
          </aside>
          
          <aside className={`flex flex-col justify-between pb-[2rem] pt-[4rem] ${type === 'login' ? 'min-h-[230px] max-h-[230px]':'h-full'}`}>
            <h1 className="text-xl font-500 w-[220px]">
              AI-Powered Health Solutions, Tailored for Africa
            </h1>
            <small>eighty-nine © {new Date().getFullYear()}</small>
          </aside>
        </div>
      </section>
      {/* Main Content .... */}
      <section className="w-full h-full p-6 xl:px-20 overflow-y-auto">
        
          <div className="w-full flex justify-between min-h-[50px] items-center text-sm">
            <img src={Logo1} alt="" className='lg:hidden w-[120px] sm:w-[160px]' />
            <div></div>
            {subHead}
          </div>
        
        <div className="w-full h-full flex flex-col items-center pt-[4rem]">
          {content}
        </div>
      </section>
    </div>
  )
}

export default AuthWrapper