

function getDevice() {
    const ua = navigator.userAgent;

    if (/mobile/i.test(ua)) {
        return "Mobile";
    } else if (/tablet/i.test(ua)) {
        return "Tablet";
    } else {
        return "Desktop";
    }
}

export default getDevice