import { baseUrl } from "./mainServices"
import local from "../Utils/Local"
import ToastError from "../Utils/ToastErrors"
import axios from "axios"
import Toast from "../Utils/Toast"

export const authServices = {
    signup : async (data:any, type: 'student'|'partnership', hospital?:string)=>{
        try{
            return await axios.post(`${baseUrl}/accounts/register/${type==='student'?'':`?hospital=${hospital}`}`, data)
        }catch(err:any){
            ToastError(err)
            if(err.response.status === 401){
                Toast('Provided email is not a member of the specified hospital.', 'warning')
            }
        }
    },
    signin : async (data:any)=>{
        try{
            const res:any = await axios.post(`${baseUrl}/accounts/login/`, data)
            await local.set('user', res.data?.user);
            await local.set('tokens', res.data?.tokens)

            return res
        }catch(err:any){
            return err
        }
    },
    verifyEmail : async (data:any) => {
        try{
            return await axios.post(`${baseUrl}/accounts/verify/`, data)
        }catch(err){
            ToastError(err)
            return err
        }
    }
}