import axios from "axios";
import axiosInstance from "../Utils/axios";
const env = process.env.NODE_ENV;

export const baseUrl = env === 'development' ? 'http://0.0.0.0:8080/api/v1':'https://api.eightynineai.com/api/v1';
export const chatUrl = env === 'development' ? 'ws://127.0.0.1:8000' : 'wss://chat.eightynineai.com';
export const mediaUrl = env === 'development' ? 'http://0.0.0.0:8080' : 'https://api.eightynineai.com';
export const imgFail = 'https://nbhc.ca/sites/default/files/styles/article/public/default_images/news-default-image%402x_0.png?itok=B4jML1jF'

export const services = {
    getUniversities : async ()=>{
        try {
            const res = await axios.get(`${baseUrl}/accounts/universities`)
            return res.data
        }catch(err){
            return err
        }
    },
    getHospital : async ()=>{
        try {
            const res = await axiosInstance.get(`${baseUrl}/accounts/hospitals`)
            return res.data
        }catch(err){
            return err
        }
    },
    getCountries: async () =>{
        try{
            const res = axiosInstance.get(`https://restcountries.com/v3.1/all`)
            return res
        }catch(err){
            return []
        }
    },
    getUpgrade: async () =>{
        try{
            const res = axiosInstance.get(`https://restcountries.com/v3.1/all`)
            return res
        }catch(err){
            return []
        }
    },
}

