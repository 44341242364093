import React, { FormEvent, useEffect, useState } from 'react'
import { send } from '../Assets/Icons';

type props = {
    mes: string,
    setMes: (val:any)=>void,
    limitheight: number,
    setSendData?: (data:any)=>void,
    user?: any,
    hideAttach?: boolean,
    border?: boolean,
}

function MessageInput({mes, setMes, hideAttach, limitheight, setSendData, user, border}:props) {
    const [inputHeight, setInputHeight] = useState(40);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    

    useEffect(()=>{
        if(mes?.length > (innerWidth - (innerWidth > 1200 ? 150 : 70)) && inputHeight < limitheight){
            setInputHeight(Math.ceil(mes?.length/(innerWidth-(innerWidth > 1200 ? 150 : 70))) * 100)
        }
        if(mes?.length <= (innerWidth - (innerWidth > 1200 ? 150 : 70))){
            setInputHeight(40)
        }
        if(innerWidth<777){
            setInputHeight(40)
        }
        // eslint-disable-next-line
    }, [mes]);
    
    useEffect(()=>{
        setInnerWidth(window.innerWidth);
        window.addEventListener('resize', ()=>{
            setInnerWidth(window.innerWidth);
        })
    }, [])

    const handleSend =async (e:FormEvent) =>{
        e.preventDefault();
        setSendData && setSendData({
            content: mes,
            image: '',
            sender: user?.id
        })
    }
  return <form onSubmit={e=>handleSend(e)} className={`w-full rounded-2xl p-3 dark:text-gray-200 dark:bg-[#222E44] bg-white flex md:flex-row flex-col items-end ${border && 'border'}`}>
        {
            !hideAttach && <aside className="min-w-10 h-10 hidden md:flex items-center justify-center bg-gray-200 text-white rounded-full">
                <i className="fa fa-plus"></i>
            </aside>
        }
        

        <aside className="w-full h-fit">
            <textarea required style={{height: inputHeight}} value={mes} onChange={e=>setMes(e.target.value)} name="" id="" className={`w-full md:p-3  bg-transparent outline-none border-none text-xs sm:text-sm`} placeholder='Type here...'></textarea>
        </aside>
        
        <button disabled={mes.length === 0} className="min-w-[50px] h-[50px] hidden md:flex items-center justify-center bg-theme text-white rounded-2xl">
            <img src={send} alt="" className='w-[20px]' />
        </button>

        <section className="w-full flex md:hidden items-center justify-between">
            {
                !hideAttach && <aside className="min-w-8 h-8 flex items-center justify-center bg-gray-200 text-white rounded-full">
                    <small className="fa fa-plus "></small>
                </aside>
            }
            
            <button disabled={mes.length === 0} className="w-8 h-8 flex items-center justify-center bg-theme text-white rounded-2xl cursor-pointer">
                <img src={send} alt="" className='w-[17px]' />
            </button>
        </section>
    </form>
}

export default MessageInput