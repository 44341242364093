import React, { FormEvent, useState } from 'react'
import { imgFail } from '../../../Services/mainServices'
import Input from '../../../Common/Input'
import { peerExchangeServices } from '../../../Services/peerExchanges'
import useMainStore from '../../../Store/Store'


type props = {
    close: ()=>void,
    fetchData: ()=>void,
}


function CreateGroup({fetchData, close}:props) {
    const user = useMainStore().user
    const [details, setDetails] = useState<any>({
        name: '',
        profile: '',
        description: '',
        created_by: user.id
    })
    const [image, setImage] = useState('');
    const handleImageChange = (e:any) => {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setDetails((prev:any)=>{
            return {...prev, profile: file}
        })
        setImage(url)
    }

    const handleSubmit =async (e:FormEvent) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(details).forEach((key:any)=>{
            formData.append(key, details[key])
        })
        const res:any = await peerExchangeServices.createGroup(formData);
        if(res){
            fetchData();
            close();
        }
    }
    return (
        <form onSubmit={handleSubmit} className='w-full flex flex-col'>
            <section className="flex flex-col items-center">
                <aside className="w-[100px] h-[100px] relative rounded-xl overflow-hidden mb-2">
                    <img src={image ? image : imgFail} alt="" className='w-full h-full object-cover' />
                    <input id={'imgFile'} accept='image/*' onChange={e=>handleImageChange(e)} type="file" name="" className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" />
                </aside>
                <small className={`${image&&`cursor-pointer hover:text-sky-500`}`} onClick={()=>document.getElementById('imgFile')?.click()}>{image ? 'Change Image':'Group Profile'}</small>
            </section>
            <div className="w-full flex flex-col my-3 mb-4">
                <Input type='text' value={details.name} onChange={val=>setDetails({...details, name: val})} placeholder='Enter group name' className='h-12 border-gray-200'  />
            </div>
            <div className="w-full flex flex-col mb-2">
                <textarea value={details.description} onChange={e=>setDetails({...details, description: e.target.value})} placeholder='Group description...' className='w-full rounded-l-xl outline-none min-h-[100px] border p-4 text-sm'/>
            </div>
            <br />
            <button className="text-white bg-theme rounded-xl w-full h-12">
                Create Group
            </button>
        </form>
    )
}

export default CreateGroup