import React, { useEffect, useRef, useState } from 'react'
import { send } from '../../../../Assets/Icons'
import useMainStore from '../../../../Store/Store'
import { peerExchangeServices } from '../../../../Services/peerExchanges'
import { convertDate, convertTime } from '../../../../Utils/convertTime'
import { chatUrl } from '../../../../Services/mainServices'

type props = {
    peer: string
}

function Peer({peer}:props) {
    const user = useMainStore().user
    const store = useMainStore()
    const [messages, setMessages] = useState<any[]>([])
    const sendBtn = useRef<HTMLButtonElement>(null)
    const inputRef = useRef<HTMLTextAreaElement>(null)


    function convertToJson(data:any){
        let jsonString:any = data.replace(/'/g, '"').replace(/None/g, "null").replace(/\bTrue\b/g, "true").replace(/\bFalse\b/g, "false");
        return JSON.parse(jsonString)
    }
    
    useEffect(()=>{
        let board = document.getElementById('messageBorder')
        if (board && board.lastElementChild) {
            board.lastElementChild.scrollIntoView({ behavior: 'smooth' });
        }
    },[messages])

    useEffect(()=>{
        let socket = new WebSocket(`${chatUrl}/ws/individual/${user?.id}/${peer}?token=${store.tokens?.access_token}`)
        const connectSocker = () => {
            socket.addEventListener('open', (event) => {
                console.log('Connected to server.')
            });
            socket.addEventListener('close', (event) => {
                console.log('Connection closed.');
            });
            
            socket.onmessage = (event) => {
                try{
                    if(event.data?.includes('Client #')){
                        console.log('onConnect',event.data)
                        let privateChatId = `${event.data}`.split('|')[0].split(':')[1].trim();
                        fetchAllMessages(privateChatId)
                    }else{
                        let message:any = convertToJson(event.data)
                        console.log(message);
                        
                        const data = message.created_data;
                        setMessages((prev:any)=>{
                            return [...prev, data]
                        })
                        if(inputRef.current){
                            inputRef.current.value = '';
                        }
                    }

                }catch(err){
                    console.log(event.data);
                    return
                }
            }
        };
        connectSocker();
        
        const sendMessage = (inputValue: any) => {
            let data = {
                content: inputValue,
            }
            socket.send(JSON.stringify(data))
        }
        
        if(sendBtn.current){
            sendBtn.current.addEventListener('click', (e)=>{
                if(inputRef.current && inputRef.current.value){
                    sendMessage(inputRef.current.value)
                    // inputRef.current.value = ""
                }
                
            })
        }

        return ()=>{
            socket.close()
        }
        // eslint-disable-next-line
    }, [peer])

    const fetchAllMessages =async (chatId:any) => {
        peerExchangeServices.getAllPrivateChat(chatId)
        .then(res=>{
            setMessages(res.results)
        }).catch(()=>{})
    }

  return (
    <div className={`w-full lg:min-w-[600px]`}>
        <section id='messageBorder' className="w-full min-h-52 overflow-y-auto">
            {
                messages?.map((chat:any, idx:number)=>{
                    return <div key={`chat-${idx}`}>
                        <aside className={`flex flex-col mb-3 ${chat.sender === user.id ? 'items-end':''}`}>
                            <div className={`flex items-center mb-1 ${chat.sender !== user.id ? 'flex-row-reverse justify-end':''}`}>
                                <small className={`${chat.sender !== user.id ? 'ml-2':'mr-2'} text-[0.7rem]`}>{convertDate(chat.updated_at)} | {convertTime(chat.updated_at)}</small>
                                <div className={`min-w-7 h-7 rounded-full ${chat.sender === user.id ? 'bg-theme text-white':'bg-gray-400'} flex justify-center items-center`}>
                                    <i className="fa fa-user text-[0.6rem]"></i>
                                </div>
                            </div>
                            <div className={`p-4 ${chat.sender === user.id ? 'text-theme bg-gray-200':'text-white bg-theme'} rounded-xl flex max-w-[80%] w-fit`}>
                                <div className="w-full">
                                    <small>{chat.content}</small>
                                </div>
                            </div>
                        </aside>
                    </div>
                })
            }
        </section>
        <section className="w-full border rounded-xl sticky bottom-0 flex bg-white items-center overflow-hidden p-2">
            <aside className="w-full h-fit">
                <textarea ref={inputRef} required style={{height: 70}} autoFocus name="" id="" className={`w-full md:p-3 outline-none border-none text-[12px]`} placeholder='Type here...'></textarea>
            </aside>
            
            <button ref={sendBtn} className="min-w-[45px] h-[45px] flex items-center justify-center bg-theme text-white rounded-2xl">
                <img src={send} alt="" className='w-[15px] pointer-events-none' />
            </button>
        </section>
    </div>
  )
}

export default Peer