import React, { useEffect, useRef, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import RightSideBar from '../Components/RightSideBar'
import useMainStore from '../../../Store/Store'
import MessageInput from '../../../Common/MessageInput'
import { learnService } from '../../../Services/learnHubServices'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Modal from '../../../Common/Modal'
import ShareChat from './Share'
import { mediaUrl } from '../../../Services/mainServices'
import { convertDate, convertTime, getFormattedDate } from '../../../Utils/convertTime'
import { copyText } from '../../../Utils/copyText'


function Learn() {
  const LearnContent = ()=>{
    const params = useParams();
    const mainStore = useMainStore();
    const navigate = useNavigate();
    const location = useLocation();
    const setPage = mainStore.setPage;
    const {user, setShow} = mainStore;
    const containerRef = useRef<HTMLDivElement>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);
    const [modal, setModal] = useState('')
    
    const scrollBottom = ()=>{
      if (containerRef.current && lastElementRef.current) {
        containerRef.current.scrollTop = lastElementRef.current.offsetTop;
      }
    }
  
    useEffect(()=>{
      setPage('Learning Hub');
      scrollBottom();
      return ()=>{
        setPage('')
      }
      // eslint-disable-next-line
    }, []);

    const [mes, setMes] = useState('');
    const [isNewChat, setNewChat] = useState(true);
    const [history, setHistory] = useState([])
    const [activeChats, setActiveChats] = useState<any[]>([])

    const fetchHistory = async () =>{
      let data:any = await learnService.getHistory();

      setHistory(data?.data?.results)
      setDisplayHis(data?.data?.results)
    }

    useEffect(()=>{
      fetchHistory();
    },[])

    const handleSelect =async (his:any) =>{
      setNewChat(false)
      const chat:any = await learnService.getChat(his.id || his);
      setActiveChats(chat.data?.results || [])
      if(!params.chat){
        navigate(`/learn-hub/${his.id || his}`, {state: {activeChat: his}})
      }

      if (containerRef.current && lastElementRef.current) {
        containerRef.current.scrollTop = lastElementRef.current.offsetTop;
      }
    }

    const isSender = (id:any) => {
      return id === user.id ? true : false;
    }

    useEffect(()=>{
      if(params.chat){
        handleSelect(params.chat)
      }else{
        setNewChat(true)
      }
      // eslint-disable-next-line
    }, [params])

    const [displayHis, setDisplayHis] = useState<any>([])
    const [selectedChat, setSelectedChat] = useState<any>(null)
    useEffect(()=>{
      if(location.state?.activeChat){
        setSelectedChat(location.state?.activeChat)
      }else{
        setSelectedChat(null)
      }
    },[location.state])
    
    
    const displaySentMessage = async (mes:any) => {
      let currentChatSet = activeChats;
      let currentChatId = ''
      if(isNewChat){
        let chatBody = {
          title: mes?.content?.split(' ')[0],
          description: mes?.content?.slice(0,100)
        }
        const res:any = await learnService.createChat(chatBody);
        const status = res.status || res?.response?.status
        if(status === 201){
          fetchHistory()
          navigate(`/learn-hub/${res.data?.id}`, {state: {activeChat: res.data}})
          currentChatSet = []
          currentChatId = res.data?.id
        }
      }


      // Add the user's message to the current chat set and update the state
      currentChatSet = [...currentChatSet, {...mes, sender: user.id, updated_at: getFormattedDate()}];
      setActiveChats(prev=>{
        return [...currentChatSet]
      });
      sendMessage(mes, currentChatSet, currentChatId || '');
          // Call sendMessage after updating the state
    }

    const sendMessage = async (mes:any, currentChatSet: any, currentChatId?: string) => {
        let data = {
            content: mes.content,
            image: mes.image,
            updated_at: getFormattedDate()
        };

        // Send the message to the server
        let sendRes:any = await learnService.sendMes(currentChatId || params.chat, data);
        const status = sendRes.status || sendRes?.response?.status;

        if(status === 201){
            let currentChatSetNew = [...currentChatSet, {...sendRes.data.ai_response}];
            setActiveChats(currentChatSetNew);

            setMes('')
            scrollBottom();
        }
    }

    const [searchHis, setSearchHis] = useState('')
    useEffect(()=>{

      let searchText = searchHis.trim()
      let res:any[] = history.filter((his:any)=>`${his.title}`.trim().toLowerCase()?.includes(searchText?.toLowerCase().trim()));
      
      if(res?.length>0){
        setDisplayHis(res)
      }else{
        setDisplayHis(history)
      }
      // eslint-disable-next-line
    }, [searchHis])


    return <div key={'learnHub'} className='w-full flex h-full relative'>
      <section className="w-full dark:bg-darkBg bg-[#E0E6EB] rounded-lg lg:rounded-[32px] p-4 flex flex-col items-center justify-between">
        
        <div className="w-full 2xl:px-[10%] flex flex-col items-center h-[calc(100%-150px)] overflow-hidden overflow-y-auto">
          {/* Chat Budges ....... */}
          <section className="overflow-x-auto hidden items-center justify-between h-[120px]">
            <div className="flex w-fit">
              <button className="rounded-[30px] w-fit bg-white flex p-1 mr-2">
                <div className="w-full h-[40px] text-[12px] px-3 flex items-center justify-center text-[#5D5B73] text-nowrap">
                  Colonoscopy
                </div>
                <div className="h-[40px] min-w-[40px] flex items-center justify-center bg-skyBlue rounded-full text-white">
                  <small className="fa fa-times"></small>
                </div>
              </button>
              <button className="rounded-[30px] w-fit bg-white flex p-1 mr-2">
                <div className="w-full h-[40px] text-[12px] px-3 flex items-center justify-center text-[#5D5B73] text-nowrap">
                  Colorectal Cancer
                </div>
                <div className="h-[40px] min-w-[40px] flex items-center justify-center bg-skyBlue rounded-full text-white">
                  <small className="fa fa-times"></small>
                </div>
              </button>
              <button className="rounded-[30px] w-fit bg-white flex p-1">
                <div className="w-full h-[40px] text-[12px] px-3 flex items-center justify-center text-[#5D5B73] text-nowrap">
                  Add Topic here
                </div>
                <div className="h-[40px] min-w-[40px] flex items-center justify-center bg-skyBlue rounded-full text-white">
                  <small className="fa fa-times"></small>
                </div>
              </button>
            </div>
          </section>

          {/* Chart Board ....... */}
          <section ref={containerRef} className="w-full h-full flex flex-col overflow-y-auto">
            {
              !isNewChat ? (
                  <>
                    {activeChats?.map((chat: any) => {
                      // Sent Message logic here
                      if (isSender(chat.sender)) {
                        return (
                          <aside
                            key={`sender-${chat.id}`}
                            className="w-full flex justify-start py-[1rem] dark:text-white"
                          >
                            <div className="w-[90%] lg:w-[80%] flex">
                              <div className="min-w-8 h-8 rounded-full overflow-hidden mr-2 flex justify-center items-center bg-white dark:text-theme">
                                {
                                  user?.avatar ? <img
                                    src={`${mediaUrl+user?.avatar}`}
                                    alt=""
                                    className="w-full h-full object-cover"
                                  /> : <small className="fa fa-user"></small>
                                }
                              </div>
                              <aside className="w-full mt-1 flex flex-col">
                                <small className="md:text-[14px] text-[11px]">
                                  {chat?.content}
                                </small>
                                <small className='text-gray-500 text-[0.8rem] mt-2'>
                                    {`${convertDate(chat?.updated_at)} at ${convertTime(chat?.updated_at)}`}
                                  </small>
                              </aside>
                            </div>
                          </aside>
                        );
                      }
                      return (
                        <aside
                          key={`receiver-${chat.id}`}
                          className="w-full flex justify-end py-[2rem]"
                        >
                          <div className="w-[90%] lg:w-[80%] flex bg-[#EDF8FF] p-3 rounded-lg">
                            <i className="fa fa-star mr-2 mt-[0.1rem] text-emerald-600"></i>
                              <aside className="w-full">
                                <small className="md:text-[0.9rem] text-[0.85rem]">
                                  {chat?.content}
                                </small>
                                <div className="w-full flex items-center justify-end mt-2">
                                  <aside onClick={()=>copyText(chat?.content)} className="flex items-center mr-2 bg-white rounded-lg px-2 py-1 cursor-pointer hover:bg-theme hover:text-white">
                                    <small className="fa fa-copy mr-2"></small>
                                    <small>Copy</small>
                                  </aside>
                                  <small className='text-gray-500 text-[0.8rem]'>
                                    {`${convertDate(chat?.updated_at)} at ${convertTime(chat?.updated_at)}`}, 
                                  </small>
                                </div>
                              </aside>
                          </div>
                        </aside>
                      );
                    })}
                  </>
                ) : <>
                <aside className="w-full flex justify-center text-3xl opacity-20 dark:text-white items-center h-full">
                  Start New Chat
                </aside>
              </>
            }
          </section>
        </div>

        <MessageInput setSendData={(data:any)=>{
          displaySentMessage(data)
        }} user={user} mes={mes} setMes={(val)=>setMes(val)} limitheight={500}/>
      </section>

      <RightSideBar title='History' content={<>
        <div className="w-full flex flex-col items-center justify-between px-4 py-5 sticky top-0 dark:bg-darkBg bg-[#F1EFEF] dark:text-white">
          <div className='flex items-center justify-between w-full hover:text-theme cursor-pointer'>
              <div onClick={()=>{
                setNewChat(true);
                navigate('/learn-hub')
              }} className="w-full h-10 rounded-full cursor-pointer overflow-hidden flex items-center">
                <i className="fa fa-edit text-2xl group-hover:scale-95 mr-2"></i>
                {isNewChat && <b className='mt-1'>Create new Chat</b>}
              </div>
              {
                selectedChat && <button onClick={()=>setModal('Share Chat')} className="px-8 py-2 rounded-2xl bg-theme text-white">Share</button>
              }
          </div>
          <div className="w-full py-3 flex items-center justify-between mt-2">
            {
              searchHis ? <div className="w-full flex items-center border rounded-lg overflow-hidden dark:bg-darkBg bg-white border-gray-300">
                <input type="text" autoFocus value={searchHis} onChange={e=>setSearchHis(e.target.value)} placeholder='Search chat history' className="w-full h-10 bg-transparent border-none outline-none px-2 pl-4 text-xs" />
                <i onClick={()=>setSearchHis('')} className="cursor-pointer fa fa-times text-red-500 px-3"></i>
              </div> : <>
                <p>Chat history</p>
                <button onClick={()=>setSearchHis(' ')} className="w-10 h-10 rounded-xl flex items-center justify-center bg-[#6B7280] text-white">
                  <i className="fa fa-search"></i>
                </button>
              </>
            }
          </div>
        </div>
        
        {
          displayHis?.map((his:any, idx:number)=>{
            return <div key={`chat-his-${idx}`} onClick={()=>{
              handleSelect(his)
              navigate(`/learn-hub/${his.id}`, {state: {activeChat: his}, replace: true})
              setShow(false)
            }} className={`w-full group hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer p-4 px-4 ${his.id === params.chat && 'bg-[#4297c81f]'} flex flex-col border-y border-gray-300`}>
            <aside className="w-full flex items-center justify-between mb-1">
              <h1 className="text-[16px] dark:text-white text-theme font-semibold"><b className="mr-1">{idx+1}.</b> {`${his.title}`?.slice(0,10)}...</h1>
              <small className='text-gray-500'>{convertDate(his.created_at)}</small>
            </aside>
            <p className='hidden group-hover:block transition-all duration-700 text-[14px] dark:text-gray-300 text-black'>{his.description?.length > 100 ? `${his.description?.slice(0,100)}...`:his.description}</p>
          </div>
          })
        }
        
        </>}/>

        {
          modal && <Modal title={modal} close={()=>setModal('')} content={<>
            {
              modal === 'Share Chat' ? <ShareChat setSelectedChat={(val:any)=>{
                setSelectedChat(val)
                fetchHistory()
              }} close={()=>setModal('')} selectedChat={selectedChat}/> : <></>
            }
          </>}/>
        }
        
    </div>
  }
  return <MainWrapper content={<LearnContent/>}/>
}

export default Learn