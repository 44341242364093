import React from 'react'
import { FaUserDoctor } from 'react-icons/fa6'

function DashShema() {
  return (
    <div className='w-full h-full grid gap-3'>
        <section className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 h-fit">
            {[1,2,3,4].map(()=><div className="w-full rounded-lg dark:bg-gray-700 bg-gray-300 animate-pulse h-[100px] md:h-[150px] flex flex-col items-center justify-center">
                <div className="h-1 dark:bg-gray-600 bg-gray-400 rounded-lg w-[30%] my-1 animate-pulse"></div>
                <div className="h-1 dark:bg-gray-600 bg-gray-400 rounded-lg w-[70%] my-1 animate-pulse"></div>
                <div className="h-1 dark:bg-gray-600 bg-gray-400 rounded-lg w-[50%] my-1 animate-pulse"></div>
                <div className="h-1 dark:bg-gray-600 bg-gray-400 rounded-lg w-[60%] my-1 animate-pulse"></div>
            </div>)}
        </section>
        <section className="w-full grid md:grid md:grid-cols-2 2xl:grid-cols-2 gap-3 h-full">
            {[1,2].map(()=><div className="w-full rounded-lg dark:bg-gray-700 bg-gray-300 animate-pulse h-[200px] md:h-[300px] flex items-center justify-center">
                <FaUserDoctor className={`text-[40px] dark:text-gray-600 text-gray-500 mb-2 animate-pulse`}/>
            </div>)}
        </section>
        <section className="w-full rounded-lg dark:bg-gray-700 bg-gray-300 animate-pulse h-[200px] md:h-[300px] flex items-center justify-center">
            <FaUserDoctor className={`text-[40px] dark:text-gray-600 text-gray-500 mb-2 animate-pulse`}/>
        </section>
    </div>
  )
}

export default DashShema