import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MessageInput from '../../../../Common/MessageInput';
import useMainStore from '../../../../Store/Store';
import axiosInstance from '../../../../Utils/axios';
import Toast from '../../../../Utils/Toast';
import Empty from '../../../../Common/Empty';
import { convertDate, convertTime, getFormattedDate } from '../../../../Utils/convertTime';
import ToastError from '../../../../Utils/ToastErrors';

type props = {
    selectedPost: any,
    setGroupMessage: (val:any)=>void,
    groupMessage: any[]
}

function PostComment({selectedPost, setGroupMessage, groupMessage}:props) {
    const user = useMainStore().user;
    const location = useLocation();
    const [post, setPost] = useState<any>()
    const [mes, setMes] = useState('')

    useEffect(()=>{
        setPost(selectedPost?.post)
    },[selectedPost, location.state])

    const handleSendComment = ()=>{
        let data = {
            user: user?.id,
            content: mes,
            group_message: selectedPost?.post?.message?.id,
            created_at: getFormattedDate(),
            updated_at: getFormattedDate(),
        }

        axiosInstance.post(`/peerexchange/groups/group/comment/post/`, data)
        .then(res=>{
            Toast('Comment sent');
            let comments = [{...data, user}, ...post?.comments];
            setPost((prev:any)=>{
                return {...prev, comments}
            });

            let messages = [...groupMessage];

            messages.splice(selectedPost?.idx, 1, {...selectedPost?.post, comments, comments_count: post?.comments_count+1})
            setGroupMessage(messages);
        }).catch(err=>{
            ToastError(err)
        })
    }

  return (
    <div className='w-[600px] p-4 pt-0 flex flex-col'>
        {/* <section className="w-full sm:rounded-[2rem] h-[120px] overflow-hidden">
            {
            Boolean(chat?.profile) ? <img src={mediaUrl+ chat?.profile} alt="" className="w-full h-full object-cover" /> :
            <img src={'https://nbhc.ca/sites/default/files/styles/article/public/default_images/news-default-image%402x_0.png?itok=B4jML1jF'} alt="" className="w-full h-full object-cover" /> 
            }
        </section> */}
        {/* <br /> */}
        <section className="w-full flex-col flex">
            <div className="w-full flex items-center justify-between py-2">
                <b>All Comments</b>
                <small className='my-2'>{post?.comments_count} Comments</small>
            </div>
            {
                post?.comments?.map((comment:any, idx:number)=>{
                    return <div key={`comment-${idx}`} className="w-full p-2 px-4 rounded-xl flex flex-col bg-gray-100 mb-3">
                        <aside className="flex w-full items-center justify-between mb-3 mt-1">
                            <div className="flex items-center">
                                <aside className="min-w-6 h-6 mr-1 flex items-center justify-center rounded-full border bg-gray-300">
                                    {
                                        comment?.user?.avatar ? <img src={comment?.user?.avatar} alt="" className='w-full h-full object-cover' /> :
                                        <small className="fa fa-user text-xs"></small>
                                    }
                                </aside>
                                <small className='text-theme font-semibold'>{`${comment?.user?.first_name} ${comment?.user?.last_name}`}</small>
                            </div>
                            <small className='text-green-700'>{`${convertDate(comment?.updated_at)}, ${convertTime(comment?.updated_at)}`}</small>
                        </aside>
                        <small className='text-gray-700 text-xs'>{comment?.content?.charAt(0)?.toUpperCase()}{comment?.content?.slice(1)}</small>
                    </div>
                })
            }
            {
                post?.comments_count === 0 && <Empty/>
            }
            
            <MessageInput limitheight={100} hideAttach border mes={mes} setSendData={()=>handleSendComment()} setMes={(val)=>setMes(val)} />
        </section>
    </div>
  )
}

export default PostComment