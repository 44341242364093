import React, { useEffect, useState } from 'react'
import useMainStore from '../../../Store/Store';
import { useLocation } from 'react-router-dom';
// import { attach } from '../../../Assets/Icons';
import useGroupMessage from './hooks/useGroupMessage';
import { mediaUrl } from '../../../Services/mainServices';
import Empty from '../../../Common/Empty';
import { copyText } from '../../../Utils/copyText';
import useMain from '../../../Hooks/useMain';
import Modal from '../../../Common/Modal';
import PostComment from './Includes/PostComment';
import { convertDate, convertTime } from '../../../Utils/convertTime';
import axiosInstance from '../../../Utils/axios';
import ToastError from '../../../Utils/ToastErrors';

const modalActions = {
  postComment: 'Post Comment'
}

function GroupChat() {
    const store = useMainStore()
    const {setPage} = useMainStore()
    const location = useLocation();
    const [chat, setChat] = useState(location.state?.chat)

    const {setGroupMessage, groupMessage, handleDeletePost, drop, setDrop, inputRef, postBtn} = useGroupMessage(chat?.id, store.tokens?.access_token)
    const {modal, offModal, setModal} = useMain();
    const [selectedPost, setSelectedPost] = useState<any>()

    useEffect(()=>{
      setPage(location.state?.chat?.name);
      setChat(location.state?.chat)
      return ()=>{
        setPage('')
      }
      // eslint-disable-next-line
    }, [])

    const likePost = (post:any, idx:number, type: 'like'|'delete')=>{
      let messages = [...groupMessage];
      switch(type){
        case 'delete':
          messages.splice(idx, 1, {...post , ilike: false, likes_count: post?.likes_count-1})
          setGroupMessage(messages);
          axiosInstance.delete(`/peerexchange/groups/group/like/post/message/${post?.message?.id}/`)
          .then(res=>{
            // Toast('Delete successfully', {type: 'warning'})
          }).catch(()=>{});
          break;
        default:
          messages.splice(idx, 1, {...post, ilike: true, likes_count: post?.likes_count+1})
          setGroupMessage(messages);
          let data = {
            user: store.user?.id,
            group_message: post?.message?.id
          };
          axiosInstance.post(`/peerexchange/groups/group/like/post/message/`, data)
          .then(res=>{
            
          }).catch(err=>{
            ToastError(err)
          });
      }
    }

  return (
    <div className='w-full'>
      <section className="w-full sm:rounded-[2rem] h-[120px] overflow-hidden">
        {
          Boolean(chat?.profile) ? <img src={mediaUrl+ chat?.profile} alt="" className="w-full h-full object-cover" /> :
          <img src={'https://nbhc.ca/sites/default/files/styles/article/public/default_images/news-default-image%402x_0.png?itok=B4jML1jF'} alt="" className="w-full h-full object-cover" /> 
        }
      </section>
      <br />
      <section className="w-full bg-white rounded-md md:rounded-[1rem] p-[1rem] sm:p-[2rem] flex flex-col">
        <div className="w-full flex items-center justify-between mb-5">
          <p className="font-semibold text-deepTheme">New Post</p>
          {/* <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.25073" cy="1.75342" r="1.75" transform="rotate(-90 2.25073 1.75342)" fill="#333333"/>
          <circle cx="9.25073" cy="1.75342" r="1.75" transform="rotate(-90 9.25073 1.75342)" fill="#333333"/>
          <circle cx="16.2507" cy="1.75342" r="1.75" transform="rotate(-90 16.2507 1.75342)" fill="#333333"/>
          </svg> */}
        </div>
        <div className="w-full flex xs:flex-row flex-col">
          <aside className="w-full mr-3 min-h-[40px] max-h-[100px] flex rounded-xl overflow-hidden bg-gray-100 px-4 text-sm">
            <textarea ref={inputRef} placeholder='Type a text' className="h-full text-sm py-2 text-black w-full bg-transparent border-none outline-none" />
            {/* <div className="flex justify-center items-center min-w-[50px] h-full ">
              <img src={attach} alt="" className='' />
            </div> */}
          </aside>
          <button ref={postBtn} className="px-8 xs:mt-0 mt-3 h-[40px] rounded-xl bg-theme text-white">Post</button>
        </div>
      </section>

      <br />
      {
        groupMessage?.map((post:any, idx:any)=>{
          return <section key={idx} className="w-full animatefromtop dark:bg-darkBg  bg-white rounded-[1rem] p-[1rem] sm:p-[2rem] flex flex-col mb-[1.5rem]">
            <div className="w-full flex items-center justify-between mb-4">
              <aside className="flex items-center">
                <div className="min-w-10 h-10 rounded-full overflow-hidden border mr-2 flex items-center justify-center">
                  {
                    post?.message?.user?.avatar ? <img src={mediaUrl+post?.message?.user?.avatar} alt="" className='w-full h-full object-cover' /> :
                    <small className="fa fa-user"></small>
                  }
                </div>
                <div className="flex flex-col">
                  <p className="text-deepTheme dark:text-white font-semibold">{post?.message?.user?.first_name} {post?.message?.user?.last_name}</p>
                  <small className="text-gray-500 text-[0.8rem]">{`${convertDate(post?.message?.updated_at)}, ${convertTime(post?.message?.updated_at)}`}</small>
                </div>
              </aside>

              <div className="drop group relative">
                <div onClick={()=>{
                    if(drop !== post?.message?.id){
                      setDrop(post?.message?.id)
                    }
                  }} className="drop p-4 cursor-pointer">
                  <svg className='drop size-5' viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2.25073" cy="1.75342" r="1.75" transform="rotate(-90 2.25073 1.75342)" fill="#333333"/>
                  <circle cx="9.25073" cy="1.75342" r="1.75" transform="rotate(-90 9.25073 1.75342)" fill="#333333"/>
                  <circle cx="16.2507" cy="1.75342" r="1.75" transform="rotate(-90 16.2507 1.75342)" fill="#333333"/>
                  </svg>
                </div>
                {
                  drop === post?.message.id && <div className="drop flex absolute overflow-hidden top-5 right-0 bg-white text-gray-700 rounded-md shadow w-[150px] flex-col">
                    <div className="drop animatefromtop flex flex-col w-full p-2 shadow">
                      
                      {
                        post?.message?.user?.id === store.user?.id ? <>
                          <div onClick={()=>handleDeletePost(post, idx)} className="flex items-center border-b py-2 w-full px-2 hover:bg-red-100 hover:text-red-500 hover:font-semibold cursor-pointer rounded-md">
                            <small className="text-red-500 mr-3 fa fa-trash"></small>
                            <small>Delete</small>
                          </div>
                        </> : <></>
                      }
                      <div onClick={()=>copyText(post?.message?.content)} className="flex items-center bord py-2 w-full px-2 hover:bg-gray-100 hover:text-black hover:font-semibold cursor-pointer rounded-md">
                        <small className="text-gray-700 mr-3 fa fa-copy"></small>
                        <small>Copy</small>
                      </div>
                    </div>
                  </div>
                }
                
              </div>
              
            </div>

            <div className="w-full rounded-[1rem] p-3 bg-gray-100 dark:bg-transparent dark:text-white text-gray-700 text-xs sm:text-sm mb-4">
              <p>{post?.message.content}</p>
            </div>

            <div className="w-full flex items-center text-xs xs:text-sm text-gray-500">
              <div className="flex items-center mr-6">
                <button onClick={()=>{
                  likePost(post, idx, post?.ilike ? 'delete':'like')
                }}>
                  {
                    !post?.ilike ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-red-500">
                      <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                    </svg>
                  }
                </button>
                <div className="ml-3 cursor-pointer hover:text-theme">{post?.likes_count || 0} Likes</div>
              </div>
              <div className="flex items-center">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5608 10.9233H8.78079" stroke="#727272" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.0708 14.2134H10.7808" stroke="#727272" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M18.4307 18.4434C22.1007 14.7734 21.6607 8.55344 17.1207 5.49344C14.2907 3.58344 10.4707 3.64344 7.69075 5.61344C3.85075 8.33344 3.09075 13.3734 5.41075 17.0134L4.33075 19.6934C4.08075 20.3034 4.69075 20.9134 5.31075 20.6734L7.99075 19.5934C11.2307 21.6634 15.5907 21.2834 18.4307 18.4434Z" stroke="#727272" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div onClick={()=>{
                  setSelectedPost({post, idx})
                  setModal(modalActions.postComment)
                }} className="ml-3 cursor-pointer hover:text-theme">{post?.comments_count || 0} Comments</div>
              </div>
            </div>
          </section>
        })
      }

      {
        groupMessage?.length === 0 && <Empty/>
      }

      {
        modal && <Modal close={()=>offModal()} title={`${selectedPost?.post?.message?.content?.slice(0,15)}...`} content={<>
          {
            modal === modalActions.postComment ? <PostComment groupMessage={groupMessage} selectedPost={selectedPost} setGroupMessage={setGroupMessage}/> : <></>
          }
        </>}/>
      }
      
    </div>
  )
}

export default GroupChat