import { ReactNode, useState } from 'react'

function useMain() {
    const [modal, setModal] = useState<any | ReactNode>(null)
    const [drop, setDrop] = useState('')

    return {
        drop,
        setDrop,
        modal,
        setModal,
        offModal: ()=>{
            setModal(null);
        }
    }
}

export default useMain