import Toast from "./Toast"
export default function ToastError (err) {
    try{
        if(err.response?.data){
            if(err.response?.data?.errors){
                let toastMessage = ''
                let errObj = err.response?.data?.errors
                Object.keys(errObj).forEach((key, idx)=>{
                    toastMessage += `\n${key} - ${errObj[key]}`
                })
                Toast(err.response?.data?.detail + toastMessage, 'error')
            }else{
                Toast(err.response?.data?.message, 'error')
            }
        }else{
            Toast('Sorry, something went wrong', 'error')
        }
    }catch(e){
        return;
    }
}
