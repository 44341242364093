import React, { useEffect, useState } from 'react'
import { Logo1, avatar } from '../../../Assets/Images'
import { dash, dashActive, learn, learnActive, peer, peerActive, scan, scanActive, settings, settingsActive, starRate } from '../../../Assets/Icons'
import NavItem from './NavItem';
import useMainStore from '../../../Store/Store';
import Modal from '../../../Common/Modal';
import Upgrade from './Upgrade';
import { upgradeServices } from '../../../Services/upgradeServices';
import { useNavigate } from 'react-router-dom';

type props = {
    close: ()=>void;
    show: boolean
}

function Sidebar({close, show}:props) {
    const navigate = useNavigate();
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const store = useMainStore()
    const [upgrade, setUpgrade] = useState(false)

    useEffect(()=>{
        setInnerWidth(window.innerWidth)
        window.addEventListener('resize', ()=>{
            setInnerWidth(window.innerWidth)
        })

        upgradeServices.userPlan()
        .then(res=>{
            if(res.data && !res.data?.is_expired){
                store.setTransaction({
                ...res.data?.transaction,
                can_join_groups_no: res.data?.can_join_groups_no,
                id: res.data?.id,
                is_expired: res.data?.is_expired,
                learninghub_credits: res.data?.learninghub_credits,
                reloaded_at: res.data?.reloaded_at,
                smartscan_credits: res.data?.smartscan_credits,
                })

                store.setUser(res.data?.user)
            }else{
                store.setTransaction(null)
            }
        }).catch(()=>{})
        // eslint-disable-next-line
    },[])
    
    if(show && innerWidth < 1024){
        return <section className="fixed top-0 left-0 w-screen h-[100dvh] flex bg-[rgba(0,0,0,0.5)] z-20 p-4">
            <div className='min-w-full xs:min-w-[254px] animatefromleft px-4 rounded-xl h-full flex flex-col justify-between text-white mr-[1.5rem] xl:mr-[2rem] bg-white overflow-y-auto relative'>
                <section className="w-full min-h-[80vh] flex flex-col">
                    <div onClick={()=>window.open(`https://www.eightynineai.com/`)} className="flex cursor-pointer items-center min-h-[80px] xs:pl-[2rem] justify-between bg-white sticky top-0">
                        <img src={Logo1} alt="" />
                        <i onClick={()=>close()} className="fa fa-times cursor-pointer text-theme"></i>
                    </div>
                    <div className="w-full h-full rounded-[32px] pt-[30px] lg:pt-[100px] bg-theme flex flex-col items-center">
                        <section className="w-full flex flex-col items-center mb-[40px]">
                            <aside className="w-[100px] h-[100px] flex items-center justify-center rounded-full bg-white overflow-hidden">
                                {
                                    store.user?.avatar ? <img src={avatar} alt="" className="object-cover h-full w-full" /> :
                                    <i className="fa fa-user text-theme"></i>
                                }
                            </aside>
                            <div className="mt-2">{store.user?.first_name || ''} {store.user?.last_name || ''}</div>
                            <div className="rounded-2xl bg-white flex items-center w-fit mt-2 px-4 py-1">
                                <img src={starRate} alt="" className='w-[18px]' />
                                <b className='ml-2 text-black'>7.5</b>
                            </div>
                        </section>

                        <section className="w-full flex flex-col pl-[1.5rem]">
                            <NavItem name='Dashboard' link={'/dashboard'} icon={dash} iconActive={dashActive}/>
                            <NavItem name='Smart Scan' link='/smart-scan' icon={scan} iconActive={scanActive}/>
                            <NavItem name='Peer Exchange' link='/peer-exchange' icon={peer} iconActive={peerActive}/>
                            <NavItem name='Learning Hub' link='/learn-hub' icon={learn} iconActive={learnActive}/>
                            <NavItem name='Settings' link='/settings' icon={settings} iconActive={settingsActive}/>
                            <div onClick={()=>{
                                close()
                                setUpgrade(true)
                            }} className={`flex cursor-pointer group items-center w-full pl-5 rounded-l-[1.5rem] h-[45px] relative mb-2`}>
                                <div className="min-w-7 h-7 flex items-center justify-center rounded-lg bg-white text-theme mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                    </svg>
                                </div>
                                <p className={`group-hover:text-skyBlue`}>Upgrade</p>
                            </div>
                            <div onClick={()=>{
                                store.reset();
                                localStorage.clear();
                                navigate('/auth/login')
                            }} className={`flex cursor-pointer group items-center w-full pl-5 rounded-l-[1.5rem] h-[45px] relative mb-2`}>
                                <p className={`group-hover:text-skyBlue`}>Logout</p>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="w-full text-theme text-center py-4">
                    <small>eighty-nine © {new Date().getFullYear()}</small>
                </section>
            </div>
            <div onClick={()=>close()} className="w-full h-full bg-transparent"></div>
        </section>
    }

    return (
        <>
            <div className='min-w-[224px] h-full hidden lg:flex flex-col justify-between text-white mr-[1.5rem] xl:mr-[2rem]'>
                <section className="w-full min-h-[80vh] h-full flex flex-col">
                    <div onClick={()=>window.open(`https://www.eightynineai.com/`)} className="flex cursor-pointer items-center min-h-[90px] pl-[2rem]">
                        <img src={Logo1} alt="" />
                    </div>
                    <div className="w-full h-full rounded-[32px] pt-[100px] bg-theme flex flex-col items-center">
                        <section className="w-full flex flex-col items-center mb-[40px]">
                            <aside className="w-[100px] h-[100px] flex items-center justify-center rounded-full bg-white overflow-hidden">
                                {
                                    store.user?.avatar ? <img src={avatar} alt="" className="object-cover h-full w-full" /> :
                                    <i className="fa fa-user text-theme"></i>
                                }
                            </aside>
                            <div className="mt-2">{store.user?.first_name || ''} {store.user?.last_name || ''}</div>
                            <div className="rounded-2xl bg-white flex items-center w-fit mt-2 px-4 py-1">
                                <img src={starRate} alt="" className='w-[18px]' />
                                <b className='ml-2 text-black'>7.5</b>
                            </div>
                        </section>

                        <section className="w-full flex flex-col pl-[1.5rem]">
                            <NavItem name='Dashboard' link='/dashboard' icon={dash} iconActive={dashActive}/>
                            <NavItem name='Smart Scan' link='/smart-scan' icon={scan} iconActive={scanActive}/>
                            <NavItem name='Peer Exchange' link='/peer-exchange' icon={peer} iconActive={peerActive}/>
                            <NavItem name='Learning Hub' link='/learn-hub' icon={learn} iconActive={learnActive}/>
                            <NavItem name='Settings' link='/settings' icon={settings} iconActive={settingsActive}/>
                            <div onClick={()=>{
                                close()
                                setUpgrade(true)
                            }} className={`flex cursor-pointer group items-center w-full pl-5 rounded-l-[1.5rem] h-[45px] relative mb-2`}>
                                <div className="min-w-7 h-7 flex items-center justify-center rounded-lg bg-white text-theme mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                    </svg>
                                </div>
                                <p className={`group-hover:text-skyBlue`}>Upgrade</p>
                            </div>
                            <div onClick={()=>{
                                store.reset();
                                localStorage.clear();
                                navigate('/auth/login')
                            }} className={`flex cursor-pointer group items-center w-full pl-5 rounded-l-[1.5rem] h-[45px] relative mb-2`}>
                                <p className={`group-hover:text-skyBlue`}>Logout</p>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="w-full dark:text-[#F7F4F1] text-theme mt-4 text-center">
                    <small>eighty-nine © {new Date().getFullYear()}</small>
                </section>
            </div>

            {upgrade && <Modal close={()=>setUpgrade(false)} content={<Upgrade close={()=>setUpgrade(false)}/>} title={'Upgrade Plan'}/>}
        </>
    )
}

export default Sidebar