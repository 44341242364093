import { baseUrl } from "./mainServices";
import axiosInstance from "../Utils/axios";
import ToastError from "../Utils/ToastErrors";

export const scanServices = {
    createPatientProfile: async (data: any)=>{
        try{
            
            const res = await axiosInstance.post(`/smartscans/users/patients/`, data, );
            return res
        }catch(err){
            ToastError(err)
            return err
        }
    },
    createScan: async (data: any)=>{
        try{
            const res = await axiosInstance.post(`${baseUrl}/smartscans/`, data);
            return res;
        }catch(err){
            ToastError(err)
            return err
        }
    },
    getScans: async (user_id: any)=>{
        try{
            const res = await axiosInstance.get(`${baseUrl}/smartscans?created_by=${user_id}`);
            return res.data;
        }catch(err){
            return err
        }
    },
    getScanDetails: async (id: any)=>{
        try{
            
            const res = await axiosInstance.get(`${baseUrl}/smartscans/results/${id}`, );
            return res;
        }catch(err){
            return err
        }
    },
}