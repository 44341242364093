import React, { useEffect, useRef, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import { Logo1 } from '../../../Assets/Images'
import { useParams } from 'react-router-dom'
import { learnService } from '../../../Services/learnHubServices';

function SharedChat() {
    const params = useParams();
    const [chat, setChat] = useState<any>({});
    const [messages, setMessages] = useState<any>([])
    const [showNotice, setShowNotice] = useState(true)
    const [loading, setLoading] = useState(true)
    const containerRef = useRef<HTMLDivElement>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);
    // const [isEmpty, setEmpty] = useState(false)
    
    const scrollBottom = ()=>{
        if (containerRef.current && lastElementRef.current) {
            containerRef.current.scrollTop = lastElementRef.current.offsetTop;
        }
    }

    const fetchData=async()=>{
        const res:any = await learnService.getSharedChat(params.chatId)
        if(res){
            setChat(res.botchat)
            setMessages(res.messages)
            setLoading(false)
            scrollBottom();
        }else{
            // setEmpty(true)
        }
    }


    useEffect(()=>{
        setTimeout(() => {
            fetchData();
            scrollBottom();
        }, 3000);
        setTimeout(() => {
            setShowNotice(false)
        }, 10000);
        scrollBottom();
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        scrollBottom();
    },[loading, messages])
    const ChatContent = () =>{
        return <section ref={containerRef} className='w-full xl:w-[900px] h-full flex flex-col items-center overflow-hidden overflow-y-auto'>
                <div className="w-full pb-4 flex flex-col bg-[#F7F4F1] sticky top-0 z-10">
                    <div className="w-full flex justify-end">
                        <img src={Logo1} alt="" />
                    </div>
                    {
                        !loading && <>
                            <h1 className="text-lg md:text-3xl text-theme font-bold">{chat?.title || ''}</h1>
                            <p className='text-[0.85rem] md:text-[1rem] mt-1'>{`${chat?.created_at}`.split('T')[0]}</p>
        
                            <small className="my-2 text-gray-500">
                                {chat?.description?.slice(0,200)}
                                {chat?.description?.length>200 && '...'}
                            </small>
                            {
                                showNotice && <div className="p-3 my-4 w-full bg-gray-50 border text-black flex">
                                    <div className="flex items-center justify-center min-w-6 h-6 mr-2 rounded-full bg-theme text-white">
                                        <small className="fa fa-info"></small>
                                    </div>
                                    <small className='text-[0.7rem] md:text-[0.9rem]'>This conversation may reflect the link creators' personlized data, which isn't shared and can meaningfully change how the model responds.</small>
                                </div>
                            }
                            
                            <hr className='border'/>
                        </>
                    }
                </div>

                {
                    !loading && <div className="w-full h-full flex flex-col">
                        {
                            messages?.map((message:any, idx:number, arr:any[])=>{
                                return <div key={`message-${idx}`}>
                                    {
                                        (idx+1)%2 !== 0 ? <section className="w-full flex flex-col justify-start mb-4">
                                            <aside className="flex md:flex-row flex-col p-3 bg-sky-600 text-white  rounded-xl min-w-[60%] max-w-[90%] md:max-w-[80%]">
                                                <div className="min-w-8 w-8 h-8 rounded-full bg-white text-sky-600 mr-3 mb-2 md:mb-0  flex items-center justify-center">
                                                    <small className="fa fa-user"></small>
                                                </div>
                                                <div className='w-full'>
                                                    <p>{message.content?.charAt(0)?.toUpperCase()}{message.content?.slice(1)}</p>
                                                    <div className="w-full flex justify-end mt-2">
                                                        {`${message.updated_at}`.split('T')[0]} - 
                                                        {`${message.updated_at}`.split('T')[1]?.split('.')[0]}
                                                    </div>
                                                </div>
                                            </aside>
                                        </section> : <section className="w-full flex flex-col items-end mb-4">
                                            <aside className="flex md:flex-row flex-col items-start p-3 bg-gray-200 text-theme rounded-xl min-w-[60%] max-w-[90%] md:max-w-[80%]">
                                                <div className=" rounded-full bg-gray-0 mt-1 mr-3 mb-2 text-gray-500 flex items-center justify-center">
                                                    <small className="fa fa-star animate-spin"></small>
                                                </div>
                                                <div>
                                                    <p className='font-semibold'>{message.content}</p>
                                                    <div className="w-full flex justify-end mt-2">
                                                        {`${message.updated_at}`.split('T')[0]} - 
                                                        {`${message.updated_at}`.split('T')[1]?.split('.')[0]}
                                                    </div>
                                                </div>
                                            </aside>
                                            
                                        </section>
                                    }
                                    {idx === arr?.length-1 && <div ref={lastElementRef} className="h-0 w-0 opacity-0"></div>}
                                </div>
                            })
                        }
                    </div>
                }
                {
                    loading && <>
                    <br /><br /><br />
                    <i className="fa fa-star animate-spin text-4xl text-theme"></i>
                    </>
                }
                
        </section>
    }
  return <MainWrapper nonAuth content={<ChatContent/>} />
}

export default SharedChat