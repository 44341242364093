import { baseUrl } from "./mainServices"
import local from "../Utils/Local"
import axiosInstance from "../Utils/axios"
import ToastError from "../Utils/ToastErrors";

export const learnService = {
    getHistory: async () => {
        try {
            
            const response = await axiosInstance.get(`${baseUrl}/learninghub/botchats/`, );
            return response;
        } catch (err) {
            return err;
        }
    }, 
    getChat: async (id:string) => {
        try {
            
            const response = await axiosInstance.get(`${baseUrl}/learninghub/botchats/${id}/messages/`, );
            return response;
        } catch (err) {
            return err;
        }
    }, 
    sendMes: async (id:any, data:any) => {
        try {
            const access = await local.get('tokens');
            const formData = new FormData();
            formData.append('content', data.content);
            formData.append('image', data.image);

            const response = await axiosInstance.post(`${baseUrl}/learninghub/botchats/${id}/messages/`, formData, {
                headers: {
                    Authorization: `Bearer ${access.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response;
        } catch (err) {
            ToastError(err)
            return err;
        }
    }, 
    createChat: async (data:any) => {
        try {
            const access = await local.get('tokens');

            const response = await axiosInstance.post(`${baseUrl}/learninghub/botchats/`, data, {
                headers: {
                    Authorization: `Bearer ${access.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response;
        } catch (err) {
            ToastError(err)
            return err;
        }
    },
    activateChatSharing: async (id:any, data:any) => {
        try {
            const access = await local.get('tokens');

            const response = await axiosInstance.patch(`${baseUrl}/learninghub/botchats/${id}/`, data, {
                headers: {
                    Authorization: `Bearer ${access.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response;
        } catch (err) {
            return err;
        }
    },
    getSharedChat: async (id:any) => {
        try {
            const response = await axiosInstance.get(`${baseUrl}/learninghub/botchats/${id}/messages/shared`);
            return response.data;
        } catch (err) {
            return err;
        }
    },

}