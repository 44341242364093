import { useEffect, useState } from 'react'
import AuthWrapper from './Components/AuthWrapper'
import { authImage2 } from '../../Assets/Images'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Input from '../../Common/Input'
import { baseUrl } from '../../Services/mainServices'
import axios from 'axios'
import OTPInput from '../../Common/OtpInput'
import ToastError from '../../Utils/ToastErrors'
import useMainStore from '../../Store/Store'
import { validatePassword } from '../../Utils/Validates'

function ForgotPassword() {
    const ForgotPasswordContent = () => {
        const user = useMainStore().user
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        const [details, setDetails] = useState({
            password: '', confirm: '', email: user ? user.email : '', code: ''
        })



        const [loading, setLoading] = useState(false)
        const handleGetResetCode = (code?:string)=>{
            setLoading(true)
            if(code){
                if(validatePassword(details.password).isValid){
                    let data = {
                        password: details.password,
                        email: location.state?.email,
                    }
                    axios.post(`${baseUrl}/accounts/reset/${code}/`, data)
                    .then(res=>{
                        navigate(`/auth/login`, {replace: true})
                        setLoading(false)
                    }).catch(err=>{
                        ToastError(err)
                        setLoading(false)
                    })
                }else{
                    return;
                }
            }else{
                axios.post(`${baseUrl}/accounts/reset-request`, {email:details.email})
                .then(res=>{
                    navigate(`/auth/forgot-password/create-new`, {state: {email:details.email}})
                    setLoading(false)
                }).catch(err=>{
                    ToastError(err)
                    setLoading(false)
                })
            }
        }

        const [mismatch, setMismatch] = useState(false)
        useEffect(()=>{
            if(details.confirm.length>4 && !(details.password === details.confirm)){
                setMismatch(true)
            }else{
                setMismatch(false)
            }
        },[details.password, details.confirm])


        if(params.step !== 'create-new'){
            return <div className={`w-full xs:w-[350px] sm:w-[400px] sm:p-6`}>
                <section className="w-full h-fit flex flex-col md:mb-[5rem]">
                    <div onClick={()=>window.history.back()} className="mb-4 w-fit flex items-center cursor-pointer hover:text-theme hover:font-bold">
                        <i className="fa fa-arrow-left mr-2"></i>
                        <small>Go Back</small>
                    </div>

                    <h1 className="font-[600] text-xl sm:text-2xl mb-4 sm:mb-6">Forgot password?</h1>
                    <small>No worriest! Just enter your email and we’ll send you a reset password link.</small>

                    <br />
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        handleGetResetCode()
                    }} action="" className="w-full flex flex-col">
                        <Input value={details.email} onChange={(email:any)=>setDetails({...details, email})} type='email' placeholder='Email address' className='mb-6 h-[48px]' />

                        <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between mb-6">
                            <button className="rounded-xl bg-theme text-white w-full sm:mt-0 mt-4 h-[48px]" disabled={loading}>{loading ? 'Loading ...':'Send Recovery Email'}</button>
                        </section>
                    </form> 
                </section>

                <section className="text-[12px] text-center sm:text-sm text-gray-500 md:mt-0 mt-[4rem]">
                    <p>Just remembered? <Link to={'/auth/login'} className="text-theme">Sign in</Link></p>
                </section>
            </div>
        }
        return <div className={`w-full xs:w-[350px] sm:w-[400px] sm:p-6`}>
            <section className="w-full h-fit flex flex-col md:mb-[5rem]">
                    <div onClick={()=>window.history.back()} className="mb-4 flex items-center cursor-pointer hover:text-theme hover:font-bold">
                        <i className="fa fa-arrow-left mr-2"></i>
                        <small>Go Back</small>
                    </div>
                    <h1 className="font-[600] text-xl sm:text-2xl mb-4 sm:mb-6">Create new password</h1>
                    <small>Please create a new password that you don’t use on any other site.</small>

                    <br /><br />
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    handleGetResetCode(details.code)
                }} action="" className="w-full flex flex-col">
                    <div className="flex flex-col mb-8">
                        <small className='mb-3'>Enter code here</small>
                        <OTPInput setValue={(code:string)=>setDetails({...details, code})}/>
                    </div>
                    <Input value={details.password} onChange={(password:any)=>setDetails(prev=>{
                    return {...prev, password}
                    })} type='password' placeholder='Create new password' className='mb-6 h-[48px]' />

                    <Input value={details.confirm} onChange={(confirm:any)=>setDetails(prev=>{
                    return {...prev, confirm}
                    })} type='password' placeholder='Confirm password' className='mb-3 h-[48px]' />
                    {mismatch && <small className={`text-red-500`}>Passwords do not match</small>}
                    {
                        (details.password.length > 4 && !validatePassword(details.password).isValid) && <>
                            {
                                validatePassword(details.password).errors.map((err, idx)=><small key={`err-${idx}`} className={`text-red-500`}>{err}</small>)
                            }
                        </>
                    }
                    

                    <br /><br />
                    <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between mb-6">
                        <button className="rounded-xl bg-theme text-white w-full sm:mt-0 mt-4 h-[48px]" disabled={loading}>{loading ? 'Loading ...':'Change Password'}</button>
                    </section>
                </form> 
            </section>
        </div>
    }

    return <AuthWrapper
        type={'forgot password'}
        sideImage={authImage2}
        content={<ForgotPasswordContent/>}
    />
}

export default ForgotPassword