import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../Utils/axios';
import local from '../../../Utils/Local';
import { useLocation } from 'react-router-dom';
import { upgradeServices } from '../../../Services/upgradeServices';
import useMainStore from '../../../Store/Store';


type props = {
    close: ()=>void;
}


function Upgrade({close}:props) {
    const {user} = useMainStore()
    const location = useLocation();
    const [plans, setPlans] = useState<any[]>([])
    const [selected, setSelected] = useState<any>({});
    const [renewal, setRenewal] = useState<string>('MONTHLY')
    const currentPlan = useMainStore().transaction?.pricing;
    const isExpired = useMainStore().transaction?.is_expired;
    const [requesting, setRequesting] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(()=>{
        axiosInstance.get(`/pricing`)
        .then(res=>{
            setPlans(res.data.results)
            setLoading(false)
        }).catch(()=>{setLoading(false)})
    },[])

    useEffect(()=>{
        if(!currentPlan || isExpired){
            setSelected(()=>{
                return plans.filter(plan=>plan?.name?.includes('ree'))[0]
            })
        }else{
            setSelected(currentPlan)
        }
    },[currentPlan, plans, isExpired])

    const handleRenewalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenewal(e.target.value);
    }

    const handleSelect = () => {
        if(selected && renewal){
            setRequesting(true)
            upgradeServices.upgrade('USD', renewal, selected)
            .then((res)=>{
                setRequesting(false)
                local.set('payment_session_id', res.data?.session_id)
                window.open(res.data?.payment_link, '_blank')
            }).catch(err=>{
                setRequesting(false)
            })
        }
        local.set('prev', location.pathname)
    }

    const encodedTitle = encodeURIComponent(`*Enterprise Update - EightyNine*`);
    const encodedDescription = encodeURIComponent(`\n\nHi EightyNine,\nPlease I want to customize my enterprise update ...\nFound below is my account details:\n\nFirst name: ${user?.first_name || 'N/A'}\nLast name: ${user?.last_name || 'N/A'}\nEmail: ${user?.email || 'N/A'}`);

    const whatsappNumber = "+16025521394";
    const email = "lindiwemutungamiri23@gmail.com";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedTitle}%20${encodedDescription}`;
    const emailUrl = `mailto:${email}?subject=${encodedTitle}&body=${encodedDescription}`;

    


    return (
        <Container className=' overflow-hidden overflow-x-auto overflow-y-auto'>
            <h1 className="text-xl font-semibold">Select plan</h1>
            <p className='md:text-[0.9rem] text-[0.8rem]'>Simple and flexible per-user pricing.</p>
            <br />

            {
                loading ? <>
                    <div className="w-[200px] rounded-lg h-10 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                    <div className="grid grid-cols-2 gap-4 my-5 pb-5 border-b">
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                        <section className="flex">
                            <div className="h-4 w-4 rounded-md dark:bg-gray-600 bg-gray-400 animate-pulse mr-2"></div>
                            <div className="w-[70%] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                        </section>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <section className="w-full rounded-lg dark:bg-gray-600 bg-gray-400 h-52 animate-pulse"></section>
                        <section className="w-full rounded-lg dark:bg-gray-600 bg-gray-400 h-52 animate-pulse"></section>
                        <section className="w-full rounded-lg dark:bg-gray-600 bg-gray-400 h-52 animate-pulse"></section>
                    </div>
                    <div className="w-full flex my-5">
                        <div className="w-[450px] rounded-md h-4 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                    </div>
                    <div className="w-full flex justify-end my-5">
                        <div className="w-[200px] rounded-lg h-10 dark:bg-gray-600 bg-gray-400 animate-pulse"></div>
                    </div>
                </> : <>
                    <section className="w-full mb-6 border-b pb-6">
                        <h1 className="md:text-lg">
                            {   
                                (currentPlan?.name?.includes('ree') && !selected?.name?.includes('ree')) ? `Upgrade to ${selected?.name}` : selected?.name
                            }
                        </h1>
                        <div className="w-full grid sm:grid-cols-2 gap-4 mt-2 md:mt-4 md:text-[0.9rem] text-[0.8rem]">
                            {
                                selected?.description?.map((feature:any, idx:number)=>{
                                    return <aside key={`plan-feature-${idx}`} className={`flex items-items ${`animateshow${idx<8 ? idx : 7}`}`}>
                                        <i className="fa fa-check mr-2"></i>
                                        <small className='flex-wrap'>{feature?.description}</small>
                                    </aside>
                                })
                            }
                        </div>
                    </section>
                    
                    <section className='w-full overflow-hidden overflow-x-auto'>
                        <div className="grid grid-cols-3 gap-4 mb-6 w-[800px]">
                            {
                                plans.map((plan, idx)=>{
                                    return <div onClick={()=>setSelected(plan)}  key={`plan-${idx}`} className={`w-full p-3 flex h-[180px] flex-col justify-between border cursor-pointer rounded-xl ${selected?.name === plan?.name ? `border-theme bg-[#dae5ff88]` : `` }`}>
                                        <div className="w-full flex items-center justify-between">
                                            <h1 className="">{plan?.name}</h1>
                                            <i className={`fa fa-check-circle ${selected?.name === plan?.name ? 'text-theme':'hidden'}`}></i>
                                        </div>

                                        <section className="flex flex-col">
                                            {
                                                plan?.name?.includes('ree')?<>
                                                    <h1 className="text-4xl font-bold text-theme">$0.00</h1>
                                                    <div className="px-4 h-6 flex items-center rounded-xl bg-orange-500 text-white w-fit mt-2 text-sm font-semibold">
                                                        Free
                                                    </div>
                                                </> : plan?.name?.includes('tandard') ? <>
                                                    <div className="flex">
                                                        {currentPlan?.id !== plan?.id && <input type="radio" name="renewal" value={'MONTHLY'} checked={renewal === 'MONTHLY' && selected?.id === plan?.id} onChange={handleRenewalChange} id="" className='mr-3' />}
                                                        <h1 className="text-xl font-bold"><small className='md:text-[0.9rem] text-[0.8rem]'>$</small> {plan?.usd_monthly_price} <small className="md:text-[0.9rem] text-[0.7rem] text-gray-500">/ month</small></h1>
                                                    </div>
                                                    <div className="flex">
                                                        {currentPlan?.id !== plan?.id && <input type="radio" name="renewal" value={'YEARLY'} checked={renewal === 'YEARLY' && selected?.id === plan?.id} onChange={handleRenewalChange} id="" className='mr-3' />}
                                                        <h1 className="text-xl font-bold"><small className='md:text-[0.9rem] text-[0.8rem]'>$</small> {plan?.usd_price_per_annum} <small className="md:text-[0.9rem] text-[0.7rem] text-gray-500">/ year</small></h1>
                                                    </div>
                                                </> : <>
                                                    <small className='text-gray-500'>
                                                        📌📌
                                                        Customise your upgrade by contacting support via email or whatsapp
                                                    </small>
                                                </>
                                            }
                                            
            
                                            {
                                                plan?.id === currentPlan?.id && <div className="px-4 h-6 flex items-center rounded-xl bg-emerald-500 text-white w-fit mt-2 text-sm font-semibold">
                                                    Current
                                                </div>
                                            }
                                        </section>
                                    </div>
                                })
                            }
                        </div>
                    </section>
                    {   
                        currentPlan?.name ? 
                        (!currentPlan?.name?.includes('ree') && selected?.name?.includes('ree')) && <section className=" w-[70%] border border-blue-300 p-3 rounded-xl flex mb-6">
                            <div className="min-w-5 h-5 rounded-full flex items-center justify-center border border-blue-300 text-blue-200 mr-2">
                                <i className="fa fa-info text-xs m-auto"></i>
                            </div>
                            <small className='text-gray-500 flex-wrap'>Are you sure you want to downgrade? This will remove your current plan privileges, shifting your account to the Free plan on Next Month.</small>
                        </section> : ''
                    }
                    
                    <b className='text-theme sm:text-[0.9rem] text-[0.8rem]'>Compare plans and pricing options</b>
                    {
                        (!currentPlan?.name?.includes('nterprise') && selected?.name?.includes('nterprise')) ? <section className='flex flex-col my-3'>
                            <a href={`mailto:${emailUrl}`} target='_blank' rel="noreferrer" className="sm:w-[400px] lg:w-1/2 flex flex-col my-2 group">
                                <div className="flex items-center justify-center h-12 rounded-md group-hover:bg-blue-700 cursor-pointer group-hover:text-blue-200 bg-blue-500 text-white">
                                    <i className="fa fa-envelope text-lg px-5"></i>
                                    <div className="flex-1">Contact via Email.</div>
                                </div>
                            </a>
                            <a href={whatsappUrl} target='_blank' rel="noreferrer" className="sm:w-[400px] lg:w-1/2 flex flex-col my-2 group">
                                <div className="flex items-center justify-center h-12 rounded-md group-hover:bg-emerald-700 cursor-pointer group-hover:text-emerald-200 bg-emerald-500 text-white">
                                    <i className="fab fa-whatsapp text-lg px-5"></i>
                                    <div className="flex-1">Contact via WhatsApp.</div>
                                </div>
                            </a>
                        </section> : 
                        <div className="flex sm:flex-row flex-col sm:justify-end font-semibold text-[0.8rem] sm:text-sm w-full my-4">
                            <div onClick={()=>close()} className="h-10 cursor-pointer rounded-lg px-10 flex items-center justify-center sm:ml-3 sm:mb-0 mb-3 bg-gray-300">
                                Cancel
                            </div>
                            {
                                currentPlan ? <>
                                    {
                                        (!selected?.name?.includes('ree') && (currentPlan?.id !== selected?.id)) && <button onClick={()=>{handleSelect()}} className={`h-10 rounded-lg px-10 flex items-center justify-center sm:ml-3 ${requesting && 'pointer-events-none'} ${(currentPlan?.id === selected?.id && !isExpired) ? 'bg-gray-400 text-black':'bg-theme text-white'}`}>
                                            {requesting ? 'Loading ...':'Continue'}
                                        </button>
                                    }
                                </> : <>
                                    {
                                        !selected?.name?.includes('ree') && <button onClick={()=>{handleSelect()}} className={`h-10 rounded-lg px-10 flex items-center justify-center sm:ml-3 ${requesting && 'pointer-events-none'} ${(currentPlan?.id === selected?.id && !isExpired) ? 'bg-gray-400 text-black':'bg-theme text-white'}`}>
                                            {requesting ? 'Loading ...':'Continue'}
                                        </button>
                                    }
                                </>
                            }
                        </div>
                    }
                </>
            }

        </Container>
    )
}

const Container = styled.div`
    width: 800px;
    @media only screen and (max-width: 1000px){
        width: 100%;
    }
`

export default Upgrade