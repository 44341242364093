import React, { useEffect, useState } from 'react'
import AuthWrapper from './Components/AuthWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { authImage2 } from '../../Assets/Images'
import { fb, google } from '../../Assets/Icons'
import Input from '../../Common/Input'
import useAuth from './Hooks/authHooks'
import useMainStore from '../../Store/Store'

function Login() {
  // const {authImage2} = useImages()

  const LoginContent = () => {
    const navigate = useNavigate();
    const store = useMainStore();
    useEffect(()=>{
      if(store.user){
        navigate(localStorage.getItem('prev')||'/', {replace: true})
      }
    })
    
    const [details, setDetails] = useState({
      email: '',
      password: ''
    })

    const {handleLogin, loading} = useAuth();


    return <div className={`w-full xs:w-[450px] sm:w-[500px] sm:p-6`}>
      <section className="w-full h-fit flex flex-col md:mb-[15rem]">
          <h1 className="font-[600] text-xl sm:text-2xl mb-[4rem]">Sign in to eighty-nine</h1>

          <div className="w-full hidden justify-between h-[43px] md:h-[48px] text-white">
            <button className="w-full rounded-xl px-4 flex items-center bg-skyBlue ">
              <img src={google} alt="" />
              <div className="w-full text-center text-[14px] font-semibold">Sign in with Google</div>
            </button>
            <div className="min-w-3 md:min-w-6"></div>
            <button className="min-w-[43px] md:min-w-[48px] h-full border rounded-xl flex items-center justify-center">
              <img src={fb} alt="" />
            </button>
          </div>
          
          <div className="w-full hidden items-center text-gray-500 my-8">
            <hr className="text-gray-200 w-full" />
            <div className="text-sm mx-4">or</div>
            <hr className="text-gray-200 w-full" />
          </div>
          
          <form onSubmit={e=>handleLogin(e, details)} action="" className="w-full flex flex-col">
            <Input value={details.email} onChange={(email:any)=>setDetails(prev=>{
              return {...prev, email}
            })} type='text' placeholder='Email address' className='mb-8 h-[48px]' />

            <Input value={details.password} onChange={(password:any)=>setDetails(prev=>{
              return {...prev, password}
            })} type='password' placeholder='Password' className='h-[48px]' />
            <br />

            <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between">
              <Link to={'/auth/forgot-password'} className='text-[14px] text-theme'>Forgot password?</Link>
              <button className="rounded-xl bg-theme hover:bg-[#0e3692b5] focus:bg-[#0e3692b5] text-white w-full sm:mt-0 mt-4 sm:w-[160px] h-[48px]">{loading ? 'Logging in...':'Sign In'}</button>
            </section>
          </form>
      </section>

      <section className="text-[12px] sm:text-sm text-gray-500 md:mt-0 mt-[4rem]">
        <p>Protected by reCAPTCHA and subject to the <b className="text-theme font-normal">eighty-nine Privacy Policy</b> and <b className="text-theme font-normal">Terms of Service.</b></p>
      </section>
    </div>
  }

  

  return <AuthWrapper 
    type={'login'}
    sideImage={authImage2}
    subHead={<p className='flex sm:flex-row flex-col items-end'>New user? <Link to={'/auth/signup'} className='text-theme hover:font-semibold text-[14px] ml-1'>Create an account</Link></p>}
    content={<LoginContent/>}
  />
}

export default Login