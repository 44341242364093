import { baseUrl } from "./mainServices";
import axiosInstance from "../Utils/axios";

export const upgradeServices = {
    upgrade: async (currency:string, renewal:string, data: any)=>{
        try{
            
            const res: any = await axiosInstance.get(`${baseUrl}/pricing/initiate-payment/${currency}/${renewal}/${data?.id}/`, );
            return res
        }catch(err){
            return err
        }
    },
    verification: async (payment_session_id:string)=>{
        try{
            
            const res: any = await axiosInstance.get(`${baseUrl}/pricing/verify-payment/${payment_session_id}`, );
            return res
        }catch(err){
            return err
        }
    },
    userPlan: async ()=>{
        try{
            
            const res: any = await axiosInstance.get(`${baseUrl}/pricing/user-pricing-plan/`, );
            return res
        }catch(err){
            return err
        }
    },
}