export function convertDate (timestamp:string) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    return formattedDate;
}

export function convertTime (timestamp:string) {
    const date = new Date(timestamp);
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensures AM/PM format
    });
    return formattedTime;

}


export function getFormattedDate() {
  const date = new Date();

  // Get the date in ISO format
  let isoString = date.toISOString(); // 2024-07-03T15:43:57.646Z

  // Manually add microseconds and return the final string
  return isoString.replace(/\.\d{3}Z$/, '.' + date.getMilliseconds().toString().padStart(3, '0') + '000Z');
}